

.menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 70px;
    background: #fff;
    padding-top: 5px;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
  }

  .menu-logo  {
    font-family: "Euclid Medium";
    margin-left: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-logo h1 {
    font-size: 24px;
    font-family: "Euclid Medium";
    color: #1967D2;
    margin: 0;
}
.menu-logo img {
    height: auto;
    max-height: 100%;
    width: 90px;
}
.header-buttons {
    margin-left: 40px;
    height: 100%;
    background: transparent;
    cursor: pointer;
border: none;



  
}
.clicked{
    border-bottom: 2.5px solid #fafafa;
    color: #fafafa;
    font-size: 18px;
}
.product-finder{
    margin-left: 15%;
    font-size: 18px;
}  
.product-finder p{
    color: #212121;
    font-size: 14px;
} 
p{ 
    
    font-weight: 400;
    font-family: "Inter", sans-serif;
    font-size: 15px;
}

.menu .new-search-field {
    margin-left:70px;
}
.menu .new-search-field .new-suggestions{
width: 500px;
}

.box{
	width: 500px;
	height: 40px;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	
  
   

}


.search-icon {
    margin-left: 16px;
	font-size: 14px;
	color: #333;

}

.img-icon {
    margin-left:20px;
    border: 1px solid #fafafa;
    background: #fafafa;
    height:30px;
    width:60px;
   margin-right: 20px;
   display: flex;
   display: flex;
   align-items: center;
   
   
}
.img-icon.add{
    width: 80px; 
justify-content: center;
   }
.img-icon.add p{
 font-size: 12px; 
 font-weight: 500;
 text-shadow: 0px 0px 0.4px #000;
}
.img-icon button{
    margin-left: 5px;
    height:15px;
    width:15px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: transparent;
   border: none;
}
.img-icon button > img{
    height:10px;
    width:10px;

}

.img-icon > img.product-image{
    height:22px;
    width:22px;
    margin-left: 8px;
    
}

.image-on-hover-icon  {
    display: none;
    height:200px;
width:200px;
margin-top: 250px;
    }
.product-image:hover + .image-on-hover-icon  {
display: inline-block;
position: absolute;
box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
}


.image-on-hover-icon:hover  {
    display: absolute;
    }
.search-field-btn {
    margin-left: -1px;
    height: 40px;
    width: 80px;
    background: #375BD2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}


.search-field-btn p {
    font-weight: 500;
    font-size: 14px;
}

.box > input{
    background-color: #f5f5f5;
	flex: 1;
    border-radius: 10px;
	height: 38px;
	border: none;
	outline: none;
	font-size: 14px;
    font-weight: 400;
    color: #000;
	padding-left: 10px;
    width: 280px;


}


.menu-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    background: #fff;
    color:#1967D2;
    padding-top: 5px;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
  }
  .menu-logo-mobile h1.logo-text{
        font-family: "Pangea Medium";
        margin-left: 20px;
        color:#1967D2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
    }
   .menu-mobile .box{
    background: #F5F5F5;
    border: none;
    margin-left: 20px;
    border-radius: 2px;
    width: 70%;
   }

   .menu-mobile .box input{
    background: #F5F5F5;
   width: 60%;
   }
