* {
  font-family: 'Suisse Regular', sans-serif;
  padding: 0;
  margin: 0;
}


@font-face {
  font-family: "Google Sans Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/google-sans-cufonfonts/ProductSans-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "Google Sans Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/google-sans-cufonfonts/ProductSans-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "Google Sans Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/google-sans-cufonfonts/ProductSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Google Sans Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/google-sans-cufonfonts/ProductSans-Light.ttf") format("truetype");
}



@font-face {
  font-family: "Pangea Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/pangea/PangeaAfrikanTrial-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Pangea Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/pangea/PangeaAfrikanTrial-Regular.otf") format("truetype");
}




@font-face {
  font-family: "Suisse Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/SuisseIntl/SuisseIntl-Bold.otf") format("truetype");
}


@font-face {
  font-family: "Suisse Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/SuisseIntl/SuisseIntl-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Suisse Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/SuisseIntl/SuisseIntl-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Euclid Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/euclid-circular-b-cufonfonts/Euclid Circular B Light.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/euclid-circular-b-cufonfonts/Euclid Circular B Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/euclid-circular-b-cufonfonts/Euclid Circular B Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid SemiBold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/euclid-circular-b-cufonfonts/Euclid Circular B SemiBold.ttf") format("truetype");
}

.skeleton {
  height: 100%;
  width: 170px;
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: .5rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
.choose-method-obj{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choose-method-obj .choose-method-text{
  margin-bottom: 120px;
  margin-top: -20vh;
}

.choose-buttons-container{
  display: flex;
  justify-content: center;
}

.choose-btncon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 20px 0 20px;
}

@media (max-width: 600px){
  .choose-method-obj .choose-method-text{
    margin-bottom: 120px;
    margin-top: -40vh;
  }

  .choose-buttons-container{
   flex-direction: column;
  }

  .choose-btncon {
   
    margin: 10px;
  }
}

.choose-btn-text{
  color:#6D7380;
  font-family: "Suisse Medium"; 
  margin-bottom: 10px;
  font-size:16px;
}


.choose-btncon .choose-btn{
  font-family: "Euclid Medium";   
  height: 56px;
  width: 240px;
  font-size: 16px;
  margin: 0;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.amazon-section-container{
  display: flex;
  flex-direction: row;
}
.other-store-section-container{
  display: flex;
  flex-direction: column;
}
.aliexpress-section-container{
  display: flex;
  flex-direction: row;
}
.brand-circle{
  margin-top: 45px;
  height: 90px;
  width: 90px;
  margin-left: 25px;
  margin-right: -150px;
  border-radius: 50%;
  background: white;
  border: 2px SOLID #eeeeee;
  display: flex;
 align-items: center;
 justify-content: center;
 font-size: 16px;
 color:#000;
 font-family: "Suisse Regular";
}
.brand-circle img{
  width: 70px;
  max-height: 100%;
  height: auto;
  
}
.content-subject-title{ 
  font-family: "Google sans Regular";
  color: #202124;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 100px;
}
.a-link {
cursor: pointer;
}

.product-container{
  background: #fff;
  text-align: center;
  padding-top: 80px;
  
}

.view-more {
  width: 100px;
  height: 40px; 
  border: 1.5px SOLID #212121;
  color:#2F3545;
  border-radius: 25px;
  background: transparent;
  margin-top: -30px;
  padding-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  transition: .2s ease-in-out 0s;
}
.view-more:hover {
  transform: scale(1.05);
  cursor: pointer;
}

body {
  background: #fff;
}

.storesByImageTitle {
  text-align: left;
  margin-left: 60px;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
}

.storesByImageTitle .title-section-stores-by-title {
  color: #424242;
  margin: 0;
}
.storesByImageTitle .title-section-stores-by-title:last-child {
  color: #3D68FB;
  margin-left: 6px;
}

.ui.cards > .cardd {
  height: 100%;
  min-width: 150px;
  width: 100% !important;
  box-shadow: none;
  border-radius: 8px;
}

@media (min-width:800px) {
  
.ui.grid.container {
  grid-template-columns: repeat(4, 1fr) !important;
}
}

.sorting-div{
  margin-top: 20px;
  width: 23%;
  padding-right: calc(23% - 310px);
  margin-left: 70px;
}
.sorting-part-sign{
  height: 16px;
  width: 16px;
}
.sorting-part-text{
  font-size: 17px;
  font-family: "Suisse Regular";
  color: #424242;
}
.sorting-part-data{
  display: flex;
  margin: 25px 0;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.sorting-part{
  border-top: 1px solid #fff;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #E0E0E0;

}
.stores-filter-list{
  max-height: 130px;
  overflow-y: scroll;
 
}
.stores-filter-list::-webkit-scrollbar {
  display: none;
}

.one-store-filter{
  display: flex;
  margin-left: 5px;
  margin-bottom: 10px;
}

.storeName{
  font-size: 14px;
  font-family: "Inter";
  color: #424242;
  margin-left: 10px;
}

.products-first-row{
  display: flex;
}
.ui.grid.container.three-row {
  grid-template-columns: repeat(3, 1fr) !important;
  padding-right: 50px;
}
.ui.cards > .cardd > .image {
  max-width: 225px;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
  
}
.ui.cards > .cardd > .overlay {
  width: 90%;
  height: 90%;
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.preloading-text {
  font-size: 24px;
  font-weight: 600;
  margin-top: calc(50vh + 20px + 30px);
  margin-bottom: calc(50vh);
  color: #424242;
  margin-left: 5px;
}


.action-buttons {
  margin-bottom: 50px;
display: none;
margin-left: 30px;
  
}
.action-buttons > .action-button-container{
 display: flex;

  
}

.action-buttons > .action-button-container> .action-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  
}

.action-buttons > .action-button-container> .action-button img {
  width: 30px;
  height: 30px;

  
}
.action-buttons > .action-button-container:last-child > .action-button img {
  width: 22px;
  height: 22px;
}

.tool-tip{
  --scale: 0;
  background: #333333;
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  width: max-content;
  max-width: 100%;
  margin-left: 20px;
  margin-top: 5px;
  height: max-content;
  transform: scale(var(--scale));
  transition: 150ms transform;
}

.action-button:hover + .tool-tip{
  --scale: 1;
}




.overlay:hover .action-buttons {
 
  display: inline-block;
  animation: vissabilityAnimation 0s 0.6s forwards;
  visibility: hidden;
 
}
@keyframes vissabilityAnimation {
  to   {   visibility: visible;
  }
}
 

.ui.cards > .cardd > .overlay{
  transition: 0.5s background-color;
 
 }
.ui.cards > .cardd > .overlay:hover{

  background-color:rgba(0, 0, 0, 0.1);
  transition-delay: 0.3s; 
  width: 100%;
  height: 100%;

}
.tooltip {
  color: #fff;
}
.ui.cards > .cardd > .image > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}


.ui.cards > .cardd > .description {
  height: 36px;
  margin-bottom: 0px;
  overflow: hidden;
  
}
.ui.cards > .cardd > .rating > p {
  color: #757575;
  font-size:12px;
  margin-right: 4px;
  margin-top: 1px;
  font-weight: 500;
  margin-bottom: 0px;
}
.ui.cards > .cardd > .header{
  font-family: 'Suisse Regular', sans-serif;
  font-size:14px;
  font-weight: 500;
  text-align: left;
  line-height: normal;
  color: #212121;
  margin-bottom: 4px;
 
}
.ui.cards > .cardd > .rating .number-rating{

  font-size: 11px;
  margin-left: 2px;
  margin-top: 1px;
  font-weight: 400;
  margin-bottom: 0px;
}
.ui.cards > .cardd .meta.price {
  color:#212121;
 font-family: "Suisse Medium", Helvetica, sans-serif;
text-align: left;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
}
.ui.cards > .cardd .meta {
  font-family: 'Suisse Medium', sans-serif;
  color: #757575;
  text-align: left;
  font-size: 12px;
  font-weight: 500;

  
}
.rating {
  margin-bottom: 0px;
  display:flex;
}

.line-div {
  width: 100%;
   
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 10px;
  margin-top: -5px;
}
.ui.cards > .cardd .meta.price > a {
  font-size: 1.3rem;
  color: #222;

}


.ui.grid.container {
  margin-top: 45px;
  width: 100%;
  height: 100%;
  display: grid;
  margin-bottom: 25px;
  row-gap: 52px;
  column-gap: 17px;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(2, 1fr);
}

.ui.grid > .row {
  background: #fff;
}
.ui.grid > .row > .column.lp {
  padding: 20px 40px 20px 20px;
  align-self: flex-start !important;
}
.ui.grid > .row > .column.rp {
  padding: 20px 20px 20px 40px;
  text-align: left;
  align-self: flex-start !important;
}

.ui.grid > .row > .column > img,
.ui.grid > .row > img {
  height: 100%;
}
.ui.placeholder .header:not(:first-child):before,
.ui.placeholder .image:not(:first-child):before,
.ui.placeholder .paragraph:not(:first-child):before {
  display: none;
}

.ui.label,
.ui.labels .label {
  font-size: 22px;
}

.column.rp h1 {
  color: #333;
}
.column.rp p {
  font-size: 18px;
  color: #777;
}
.ui.placeholder.segment .column .button,
.ui.placeholder.segment .column .field,
.ui.placeholder.segment .column textarea,
.ui.placeholder.segment .column > .ui.input {
  background-color: #ff3e6c;
  border: 1px solid #ff3e6c;
  color: #fff;
  font-size: 18px;
  margin-left: 0;
}

.grid-header-content{
  margin-top: 20px;
}

.progress-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progress-tutorial-vids{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  
}
.serchbyimage-content-title.progress-title{
  margin-top: 100px;
  font-size: 24px;
  
}

.progress-tutorial-vids .searchbyimage-video-body{
  max-height: 340px;
  max-width: 340px;

}
.searchbyimage-video-body img {
  width: 100%;
  height: 100%;
  max-height: 340px;
  max-width: 340px;

  border-radius: 8px;
}
.progress-vid-obj{
  margin:0 15% 0 0;
}
.progress-vid-obj:first-child{
  margin:0 0 0 15%;
}
.how-to-tips{
  text-align: left;
  margin-left: 15%;
  margin-top: 50px;
  font-size: 28px;
  
}
.progress-bar {
  margin: 50px 0 0 0 ;
  position: relative;
  height: 20px;
  width: 70%;
  background: #E0E0E0;
  border-radius: 25px;
display: flex;

}
@keyframes last-progress {
  0% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
} 

.serchbyimage-content-title.prog-vid-title{
color:#3D68FB;
font-size: 20px;
text-align: left;
margin-bottom: 20px;
margin-top: 30px;
}

.progress-fill {
  height: 20px;
  width: 95%;
  animation: progress-forward 15s 1;
  background: linear-gradient(
    90deg,
    #1A2B6B 0%,
    #375BD2 100%
  );
  border-radius: 25px;
}
.progress-fill.last-progress-cls {
  animation: last-progress 3s 1;
}


@keyframes progress-forward {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 95%;
  }
} 


.filter-plus-items{
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
 }


.ui.grid.container .four.wide.column {
  width: 100% !important;

  padding: 0;
}
.typing-demo {
  padding: 4px;
  width: 52ch;
  animation: typing 3s steps(52) infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
    border-color: transparent;

 
 text-align: center;
  color:#212121;
}

 
@keyframes typing {
  from {
    width: 0
  }
}
    
.regular-loading-container{
  display: flex;
  justify-content: center;
}