@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);

* {
    font-family: "Inter", sans-serif;
}
.compare-data {
    margin-top: 40px;

}
.compare-row {
    display:flex;
}
.compare-row .label {
    margin-left: 5%;
    font-size: 18px;
    font-weight: 500;
    color: #616161;
}
.compare-row .data-values{
    width: 100%;
    display:flex;
    justify-content:space-between;
}
.val-title{
    margin-left:10%;
    margin-right:5%;
    font-size: 14px;
    font-weight: 500;
    color: #212121;

    
}
.val-price{
    margin-left:10%;
   
    font-size: 18px;
    font-weight: 500;
    color: #000;
    
}
.val-price:nth-child(2){
    margin-right:20%;
    
}

.compare-products-widget h4 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    color: #212121;
    font-family: "Roboto", sans-serif;
}
.compare-images {

    display:flex;
    justify-content:space-between;
}
.img-plus-title {
    text-align:center;
    margin-left: 8%;
    margin-right: 8%;
}
.img-compare {
    max-width: 250px;
    max-height: 250px;
    object-fit: cover;
}
.compare-products-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.65);
    z-index: 200;
}

.compare-products-widget {
    margin-left: 15%;
    margin-top: 20vh;
    width: 70%;
    height: 64vh;
    background: #fff;

}
.exit-button img{
    height: 15px;
}
.exit-button {
cursor: pointer;
  background:transparent;
  border:none;
  position:absolute;
  float:right;
  right: calc(15% + 20px);  
top: calc(20vh + 20px);
}

.slider-div {
margin-left: 50px;
background: transparent;
margin-bottom: 40px;
}

.card-item {
   margin-bottom: 10px;
width: 100%;
  
}
.store-card {
    position: relative;
    margin: -12px 0 20px 20px;
    border:1px solid #e0e0e0;
    height: 160px;
    width: 95%;
    border-radius: 10px;
    background: #fff;
}
.store-product-image {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    height: 220px;
    width: 220px;
    margin: 20px 2px 0px 22px;
    width: calc(95% - 4px) ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.store-product-image img{
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    
}
.store-title-header{
    display: flex;
    margin-top: 8px;
}

.store-logo {
    height:42px;
    width:42px;
    border-radius: 50%;
    margin: 0px 0px 0px 15px;
    }
.store-title-header .store-header-text p{
    margin: 0px 0px 0px 10px;
    text-align: left;
}
.store-title-header .store-header-text .store-company-name{
    font-family: "Roboto";
    font-size: 17px;
    font-weight: 500;
    color: #212121;
}
.store-title-header .store-header-text .store-potentially-cheaper{
    margin-top: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #212121;
}
.store-product-title {
    margin-top: 10px;
    margin-left:24px;
    font-family: "Roboto";
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    height: 45px;
}
.store-action-buttons {
    display: flex;
    justify-content: center;
}
.store-action-buttons .divider-line {
height: 30px;
width: 1px;
background: #616161;
}
.store-action-buttons .store-action-button{
    background: transparent;
    color:#000;
    border:none;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    
    }
    
.change-slick-button{
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: calc(100% - 20px - 30px);
        margin-top: 170px;
        z-index: 100;
        box-shadow: rgb(0 0 0 / 18%) 0px 4px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .change-slick-button.prev{
        left: calc(40px);
    }
    .change-slick-button.prev img{
        transform: rotate(180deg);
        margin-right: 4px;
        height: 14px;
        width: 14px;
    }
    .change-slick-button img{
        margin-left: 2px;
        height: 14px;
        width: 14px;
    }

* {
  font-family: "Suisse Regular", sans-serif;
}
.loading-animation {
  height: 50px;
  width: 50px;
  position: absolute;
  z-index: 9;
}
.line-div-divider{
  width: calc( 100% - 140px);
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 20px;
  margin-left: 70px;
  margin-top: 40px;
}
.sort-after-main .sort-after{
  width: 400px;
  position: absolute;
  margin-left: 80%;
  padding-top: 0px;

}

.store-container{
    display: flex;
  align-items: center;
  justify-content: space-between;
    margin-top: 30px;
    margin-bottom: -30px;
    
  }
  
  .store-container .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .store-container .card-container{
    display: flex;
    margin-right: 10%;
  }
  .store-container .card{
    height: 80px;
    width: 150px;
    background: transparent;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    
    border-radius: 15px;
  
  
  }
  .Loading {
    border: 2px SOLID #eeeeee;
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .search-results{
    margin-left: 70px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .search-results p {
    color: #616161;
    font-size: 14px;
  }
  .search-results h2 {
    font-family: "Suisse Regular";
    color: #212121;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px
  }


  .store-container .container .store-text{
    margin-bottom: 5px;
    font-weight:500;
    font-family: "Suisse Regular", sans-serif;
    font-size: 16px;
    color: #000
  }
  .store-container .container .aliexpress-text{
    margin-top: 4px;
  margin-bottom: 8px;
  }
  
  .store-container .container .other-store-images{
    display: flex;
    flex-direction: row;
    justify-content: center;
   
  }
  
  
  .store-container .container img{
    width: 90px;
    max-height: 100%;
    height: auto;
  }
  
  .store-container .container .other-store-walmart-logo{
    width: 70px;
    max-height: 100%;
    height: auto;
  }
  .store-container .container .other-store-ebay-logo{
    width: 50px;
    max-height: 100%;
    height: auto;
  }

  .store-container-mobile {
    display: flex;
    flex-direction: column;
   
  }

  .search-results-mobile {
    margin-top: 30px;
    margin-left: 20px;
  }


  .search-results-mobile h2 {
    font-family: "Suisse Medium";
    text-align: left;
    color: #212121;
    font-size: 20px;
    font-weight: 400;
    margin-bottom:5px;
  }



  .search-results-mobile p {
    font-family: "Suisse Regular";
    text-align: left;
    color: #616161;
    font-size: 14px;
    font-weight: 400;
  }

  .store-container-mobile .line-div-divider {
margin-top: 20px;
  }


  .mobile-card-container{
    display: flex;
    justify-content: space-between;
   
  }

  .mobile-loading-card{
    width: 160px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }

  .mobile-loading-card img{
   width: 30px;
   height: 30px; 
   margin-right: 20px;
  }

  .mobile-store-text{
    font-family: "Suisse Regular";
    color: #333;
    font-size: 16px;
    margin-right: 20px;
  }
.loaders{position:relative;margin:0 auto;width:40px}.loaders:before{content:"";display:block;padding-top:100%}.circular{animation:rotate 2s linear infinite;height:100%;transform-origin:center center;width:100%;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto}.path{stroke-dasharray:1,200;stroke-dashoffset:0;stroke-linecap:round}.white{animation:dash 1.5s ease-in-out infinite,color-white 6s ease-in-out infinite}.color{animation:dash 1.5s ease-in-out infinite,color 6s ease-in-out infinite}@keyframes rotate{100%{transform:rotate(360deg)}}@keyframes dash{0%{stroke-dasharray:1,200;stroke-dashoffset:0}50%{stroke-dasharray:89,200;stroke-dashoffset:-35px}100%{stroke-dasharray:89,200;stroke-dashoffset:-124px}}@keyframes color{100%,0%{stroke:#d62d20}40%{stroke:#0057e7}66%{stroke:#008744}80%,90%{stroke:#ffa700}}@keyframes color-white{100%,0%{stroke:#fff}40%{stroke:#fff}66%{stroke:#fff}80%,90%{stroke:#fff}}body{background-color:#eee}.showbox{bottom:0;left:0;right:0;padding:5%}
.TransitionLoading{
    padding-top: calc(50vh - 150px);
    position: fixed;
    z-index: 10;
   
}




:root {
	--hue: 223;
	--bg: hsl(var(--hue),10%,90%);
	--fg: hsl(var(--hue),10%,10%);
	--primary: #375bd2;
	--trans-dur: 0.3s;
	font-size: calc(16px + (20 - 16) * (100vw - 320px) / (1280 - 320));
}

.preloader {
	text-align: center;
	max-width: 20em;
	
}
.preloader__text {
	position: relative;
	height: 1.5em;
}
.preloader__msg {
	animation: msg 0.3s 13.7s linear forwards;
	position: absolute;
	width: 100%;
}
.preloader__msg--last {
	animation-direction: reverse;
	animation-delay: 14s;
	visibility: hidden;
}
.cart {
	display: block;
	margin: 0 auto 1.5em auto;
	width: 8em;
	height: 8em;
}
.cart__lines,
.cart__top,
.cart__wheel1,
.cart__wheel2,
.cart__wheel-stroke {
	animation: cartLines 2s forwards infinite;
}
.cart__lines {
	stroke: #375bd2;
	stroke: var(--primary);
}
.cart__top {
	animation-name: cartTop;
}
.cart__wheel1 {
	animation-name: cartWheel1;
	transform: rotate(-0.25turn);
	transform-origin: 43px 111px;
}
.cart__wheel2 {
	animation-name: cartWheel2;
	transform: rotate(0.25turn);
	transform-origin: 102px 111px;
}
.cart__wheel-stroke {
	animation-name: cartWheelStroke
}
.cart__track {
	stroke: hsla(223,10%,10%,0.1);
	stroke: hsla(var(--hue),10%,10%,0.1);
	transition: stroke 0.3s;
	transition: stroke var(--trans-dur);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),10%,10%);
		--fg: hsl(var(--hue),10%,90%);
	}
	.cart__track {
		stroke: hsla(223,10%,90%,0.1);
		stroke: hsla(var(--hue),10%,90%,0.1);
	}
}

/* Animations */
@keyframes msg {
	from {
		opacity: 1;
		visibility: visible;
	}
	99.9% {
		opacity: 0;
		visibility: visible;
	}
	to {
		opacity: 0;
		visibility: hidden;
	}
}
@keyframes cartLines {
	from,
	to {
		opacity: 0;
	}
	8%,
	92% {
		opacity: 1;
	}
}
@keyframes cartTop {
	from {
		stroke-dashoffset: -338;
	}
	50% {
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: 338;
	}
}
@keyframes cartWheel1 {
	from {
		transform: rotate(-0.25turn);
	}
	to {
		transform: rotate(2.75turn);
	}
}
@keyframes cartWheel2 {
	from {
		transform: rotate(0.25turn);
	}
	to {
		transform: rotate(3.25turn);
	}
}
@keyframes cartWheelStroke {
	from,
	to {
		stroke-dashoffset: 81.68;
	}
	50% {
		stroke-dashoffset: 40.84;
	}
}


.menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 70px;
    background: #fff;
    padding-top: 5px;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
  }

  .menu-logo  {
    font-family: "Euclid Medium";
    margin-left: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-logo h1 {
    font-size: 24px;
    font-family: "Euclid Medium";
    color: #1967D2;
    margin: 0;
}
.menu-logo img {
    height: auto;
    max-height: 100%;
    width: 90px;
}
.header-buttons {
    margin-left: 40px;
    height: 100%;
    background: transparent;
    cursor: pointer;
border: none;



  
}
.clicked{
    border-bottom: 2.5px solid #fafafa;
    color: #fafafa;
    font-size: 18px;
}
.product-finder{
    margin-left: 15%;
    font-size: 18px;
}  
.product-finder p{
    color: #212121;
    font-size: 14px;
} 
p{ 
    
    font-weight: 400;
    font-family: "Inter", sans-serif;
    font-size: 15px;
}

.menu .new-search-field {
    margin-left:70px;
}
.menu .new-search-field .new-suggestions{
width: 500px;
}

.box{
	width: 500px;
	height: 40px;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	
  
   

}


.search-icon {
    margin-left: 16px;
	font-size: 14px;
	color: #333;

}

.img-icon {
    margin-left:20px;
    border: 1px solid #fafafa;
    background: #fafafa;
    height:30px;
    width:60px;
   margin-right: 20px;
   display: flex;
   display: flex;
   align-items: center;
   
   
}
.img-icon.add{
    width: 80px; 
justify-content: center;
   }
.img-icon.add p{
 font-size: 12px; 
 font-weight: 500;
 text-shadow: 0px 0px 0.4px #000;
}
.img-icon button{
    margin-left: 5px;
    height:15px;
    width:15px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: transparent;
   border: none;
}
.img-icon button > img{
    height:10px;
    width:10px;

}

.img-icon > img.product-image{
    height:22px;
    width:22px;
    margin-left: 8px;
    
}

.image-on-hover-icon  {
    display: none;
    height:200px;
width:200px;
margin-top: 250px;
    }
.product-image:hover + .image-on-hover-icon  {
display: inline-block;
position: absolute;
box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
}


.image-on-hover-icon:hover  {
    display: absolute;
    }
.search-field-btn {
    margin-left: -1px;
    height: 40px;
    width: 80px;
    background: #375BD2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}


.search-field-btn p {
    font-weight: 500;
    font-size: 14px;
}

.box > input{
    background-color: #f5f5f5;
	flex: 1 1;
    border-radius: 10px;
	height: 38px;
	border: none;
	outline: none;
	font-size: 14px;
    font-weight: 400;
    color: #000;
	padding-left: 10px;
    width: 280px;


}


.menu-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    background: #fff;
    color:#1967D2;
    padding-top: 5px;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
  }
  .menu-logo-mobile h1.logo-text{
        font-family: "Pangea Medium";
        margin-left: 20px;
        color:#1967D2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
    }
   .menu-mobile .box{
    background: #F5F5F5;
    border: none;
    margin-left: 20px;
    border-radius: 2px;
    width: 70%;
   }

   .menu-mobile .box input{
    background: #F5F5F5;
   width: 60%;
   }



.BirdLogo {
    
}
 .bird-logo{
	position: relative;
	width: 50px;
	height: 50px;
}

.hair-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

}

.hair-logo{
	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;
	left: 50%;
	background-color: #CC4B06;
	border-radius: 0 125px 125px 0;
}

.face-logo{
	position: absolute;
	width: 70%;
	height: 70%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.white-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;

}

.white-logo{
	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;
	left: 50%;
	border-radius: 0 87.5px 87.5px 0;
	background-color: white;
}

.shadow-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	
}

.shadow-logo{
	position: absolute;
	width: 50%;
	height: 50%;
	top: 50%;
	left: 50%;
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 0 0 87.5px 0;
}

.bigBeak-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 3;

}

.bigBeak-logo{
	position: absolute;
	width: 50%;
	height: 50%;
	top: 0;
	left: 0;
	background-color: #f7ce42;
	border-radius: 87.5px 0 0 0;
}

.smallBeak-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;

}

.smallBeak-logo{
	position: absolute;
	width: 25%;
	height: 25%;
	top: 50%;
	left: 25%;
	background-color: #f7a500;
	border-radius: 0 0 0 43.25px;
}

.eye-logo{
	position: absolute;
	width: 25%;
	height: 25%;
	bottom: 51%;
	left: 51%;
	background-color: #18233e;
	border-radius: 50%;
	z-index: 2;

}

* {
  font-family: 'Suisse Regular', sans-serif;
  padding: 0;
  margin: 0;
}


@font-face {
  font-family: "Google Sans Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/ProductSans-Bold.153c4f86.ttf) format("truetype");
}


@font-face {
  font-family: "Google Sans Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/ProductSans-Medium.50b60017.ttf) format("truetype");
}


@font-face {
  font-family: "Google Sans Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/ProductSans-Regular.2765c696.ttf) format("truetype");
}

@font-face {
  font-family: "Google Sans Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/ProductSans-Light.1dde435c.ttf) format("truetype");
}



@font-face {
  font-family: "Pangea Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/PangeaAfrikanTrial-Medium.f16d9f43.otf) format("truetype");
}
@font-face {
  font-family: "Pangea Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/PangeaAfrikanTrial-Regular.90684acb.otf) format("truetype");
}




@font-face {
  font-family: "Suisse Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/SuisseIntl-Bold.82cc5ae2.otf) format("truetype");
}


@font-face {
  font-family: "Suisse Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/SuisseIntl-Medium.7b37defa.ttf) format("truetype");
}

@font-face {
  font-family: "Suisse Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(/static/media/SuisseIntl-Regular.d1a6a5b0.ttf) format("truetype");
}

@font-face {
  font-family: "Euclid Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("/static/media/Euclid Circular B Light.de6ac522.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("/static/media/Euclid Circular B Regular.4a3055af.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("/static/media/Euclid Circular B Medium.da9107f1.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid SemiBold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("/static/media/Euclid Circular B SemiBold.b55694b6.ttf") format("truetype");
}

.skeleton {
  height: 100%;
  width: 170px;
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: .5rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
.choose-method-obj{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choose-method-obj .choose-method-text{
  margin-bottom: 120px;
  margin-top: -20vh;
}

.choose-buttons-container{
  display: flex;
  justify-content: center;
}

.choose-btncon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 20px 0 20px;
}

@media (max-width: 600px){
  .choose-method-obj .choose-method-text{
    margin-bottom: 120px;
    margin-top: -40vh;
  }

  .choose-buttons-container{
   flex-direction: column;
  }

  .choose-btncon {
   
    margin: 10px;
  }
}

.choose-btn-text{
  color:#6D7380;
  font-family: "Suisse Medium"; 
  margin-bottom: 10px;
  font-size:16px;
}


.choose-btncon .choose-btn{
  font-family: "Euclid Medium";   
  height: 56px;
  width: 240px;
  font-size: 16px;
  margin: 0;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.amazon-section-container{
  display: flex;
  flex-direction: row;
}
.other-store-section-container{
  display: flex;
  flex-direction: column;
}
.aliexpress-section-container{
  display: flex;
  flex-direction: row;
}
.brand-circle{
  margin-top: 45px;
  height: 90px;
  width: 90px;
  margin-left: 25px;
  margin-right: -150px;
  border-radius: 50%;
  background: white;
  border: 2px SOLID #eeeeee;
  display: flex;
 align-items: center;
 justify-content: center;
 font-size: 16px;
 color:#000;
 font-family: "Suisse Regular";
}
.brand-circle img{
  width: 70px;
  max-height: 100%;
  height: auto;
  
}
.content-subject-title{ 
  font-family: "Google sans Regular";
  color: #202124;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 100px;
}
.a-link {
cursor: pointer;
}

.product-container{
  background: #fff;
  text-align: center;
  padding-top: 80px;
  
}

.view-more {
  width: 100px;
  height: 40px; 
  border: 1.5px SOLID #212121;
  color:#2F3545;
  border-radius: 25px;
  background: transparent;
  margin-top: -30px;
  padding-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  transition: .2s ease-in-out 0s;
}
.view-more:hover {
  transform: scale(1.05);
  cursor: pointer;
}

body {
  background: #fff;
}

.storesByImageTitle {
  text-align: left;
  margin-left: 60px;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
}

.storesByImageTitle .title-section-stores-by-title {
  color: #424242;
  margin: 0;
}
.storesByImageTitle .title-section-stores-by-title:last-child {
  color: #3D68FB;
  margin-left: 6px;
}

.ui.cards > .cardd {
  height: 100%;
  min-width: 150px;
  width: 100% !important;
  box-shadow: none;
  border-radius: 8px;
}

@media (min-width:800px) {
  
.ui.grid.container {
  grid-template-columns: repeat(4, 1fr) !important;
}
}

.sorting-div{
  margin-top: 20px;
  width: 23%;
  padding-right: calc(23% - 310px);
  margin-left: 70px;
}
.sorting-part-sign{
  height: 16px;
  width: 16px;
}
.sorting-part-text{
  font-size: 17px;
  font-family: "Suisse Regular";
  color: #424242;
}
.sorting-part-data{
  display: flex;
  margin: 25px 0;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.sorting-part{
  border-top: 1px solid #fff;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #E0E0E0;

}
.stores-filter-list{
  max-height: 130px;
  overflow-y: scroll;
 
}
.stores-filter-list::-webkit-scrollbar {
  display: none;
}

.one-store-filter{
  display: flex;
  margin-left: 5px;
  margin-bottom: 10px;
}

.storeName{
  font-size: 14px;
  font-family: "Inter";
  color: #424242;
  margin-left: 10px;
}

.products-first-row{
  display: flex;
}
.ui.grid.container.three-row {
  grid-template-columns: repeat(3, 1fr) !important;
  padding-right: 50px;
}
.ui.cards > .cardd > .image {
  max-width: 225px;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
  
}
.ui.cards > .cardd > .overlay {
  width: 90%;
  height: 90%;
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.preloading-text {
  font-size: 24px;
  font-weight: 600;
  margin-top: calc(50vh + 20px + 30px);
  margin-bottom: calc(50vh);
  color: #424242;
  margin-left: 5px;
}


.action-buttons {
  margin-bottom: 50px;
display: none;
margin-left: 30px;
  
}
.action-buttons > .action-button-container{
 display: flex;

  
}

.action-buttons > .action-button-container> .action-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  
}

.action-buttons > .action-button-container> .action-button img {
  width: 30px;
  height: 30px;

  
}
.action-buttons > .action-button-container:last-child > .action-button img {
  width: 22px;
  height: 22px;
}

.tool-tip{
  --scale: 0;
  background: #333333;
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  margin-left: 20px;
  margin-top: 5px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  transform: scale(var(--scale));
  transition: 150ms transform;
}

.action-button:hover + .tool-tip{
  --scale: 1;
}




.overlay:hover .action-buttons {
 
  display: inline-block;
  animation: vissabilityAnimation 0s 0.6s forwards;
  visibility: hidden;
 
}
@keyframes vissabilityAnimation {
  to   {   visibility: visible;
  }
}
 

.ui.cards > .cardd > .overlay{
  transition: 0.5s background-color;
 
 }
.ui.cards > .cardd > .overlay:hover{

  background-color:rgba(0, 0, 0, 0.1);
  transition-delay: 0.3s; 
  width: 100%;
  height: 100%;

}
.tooltip {
  color: #fff;
}
.ui.cards > .cardd > .image > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}


.ui.cards > .cardd > .description {
  height: 36px;
  margin-bottom: 0px;
  overflow: hidden;
  
}
.ui.cards > .cardd > .rating > p {
  color: #757575;
  font-size:12px;
  margin-right: 4px;
  margin-top: 1px;
  font-weight: 500;
  margin-bottom: 0px;
}
.ui.cards > .cardd > .header{
  font-family: 'Suisse Regular', sans-serif;
  font-size:14px;
  font-weight: 500;
  text-align: left;
  line-height: normal;
  color: #212121;
  margin-bottom: 4px;
 
}
.ui.cards > .cardd > .rating .number-rating{

  font-size: 11px;
  margin-left: 2px;
  margin-top: 1px;
  font-weight: 400;
  margin-bottom: 0px;
}
.ui.cards > .cardd .meta.price {
  color:#212121;
 font-family: "Suisse Medium", Helvetica, sans-serif;
text-align: left;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
}
.ui.cards > .cardd .meta {
  font-family: 'Suisse Medium', sans-serif;
  color: #757575;
  text-align: left;
  font-size: 12px;
  font-weight: 500;

  
}
.rating {
  margin-bottom: 0px;
  display:flex;
}

.line-div {
  width: 100%;
   
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 10px;
  margin-top: -5px;
}
.ui.cards > .cardd .meta.price > a {
  font-size: 1.3rem;
  color: #222;

}


.ui.grid.container {
  margin-top: 45px;
  width: 100%;
  height: 100%;
  display: grid;
  margin-bottom: 25px;
  grid-row-gap: 52px;
  row-gap: 52px;
  grid-column-gap: 17px;
  -webkit-column-gap: 17px;
          column-gap: 17px;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(2, 1fr);
}

.ui.grid > .row {
  background: #fff;
}
.ui.grid > .row > .column.lp {
  padding: 20px 40px 20px 20px;
  align-self: flex-start !important;
}
.ui.grid > .row > .column.rp {
  padding: 20px 20px 20px 40px;
  text-align: left;
  align-self: flex-start !important;
}

.ui.grid > .row > .column > img,
.ui.grid > .row > img {
  height: 100%;
}
.ui.placeholder .header:not(:first-child):before,
.ui.placeholder .image:not(:first-child):before,
.ui.placeholder .paragraph:not(:first-child):before {
  display: none;
}

.ui.label,
.ui.labels .label {
  font-size: 22px;
}

.column.rp h1 {
  color: #333;
}
.column.rp p {
  font-size: 18px;
  color: #777;
}
.ui.placeholder.segment .column .button,
.ui.placeholder.segment .column .field,
.ui.placeholder.segment .column textarea,
.ui.placeholder.segment .column > .ui.input {
  background-color: #ff3e6c;
  border: 1px solid #ff3e6c;
  color: #fff;
  font-size: 18px;
  margin-left: 0;
}

.grid-header-content{
  margin-top: 20px;
}

.progress-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progress-tutorial-vids{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  
}
.serchbyimage-content-title.progress-title{
  margin-top: 100px;
  font-size: 24px;
  
}

.progress-tutorial-vids .searchbyimage-video-body{
  max-height: 340px;
  max-width: 340px;

}
.searchbyimage-video-body img {
  width: 100%;
  height: 100%;
  max-height: 340px;
  max-width: 340px;

  border-radius: 8px;
}
.progress-vid-obj{
  margin:0 15% 0 0;
}
.progress-vid-obj:first-child{
  margin:0 0 0 15%;
}
.how-to-tips{
  text-align: left;
  margin-left: 15%;
  margin-top: 50px;
  font-size: 28px;
  
}
.progress-bar {
  margin: 50px 0 0 0 ;
  position: relative;
  height: 20px;
  width: 70%;
  background: #E0E0E0;
  border-radius: 25px;
display: flex;

}
@keyframes last-progress {
  0% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
} 

.serchbyimage-content-title.prog-vid-title{
color:#3D68FB;
font-size: 20px;
text-align: left;
margin-bottom: 20px;
margin-top: 30px;
}

.progress-fill {
  height: 20px;
  width: 95%;
  animation: progress-forward 15s 1;
  background: linear-gradient(
    90deg,
    #1A2B6B 0%,
    #375BD2 100%
  );
  border-radius: 25px;
}
.progress-fill.last-progress-cls {
  animation: last-progress 3s 1;
}


@keyframes progress-forward {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 95%;
  }
} 


.filter-plus-items{
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
 }


.ui.grid.container .four.wide.column {
  width: 100% !important;

  padding: 0;
}
.typing-demo {
  padding: 4px;
  width: 52ch;
  animation: typing 3s steps(52) infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
    border-color: transparent;

 
 text-align: center;
  color:#212121;
}

 
@keyframes typing {
  from {
    width: 0
  }
}
    
.regular-loading-container{
  display: flex;
  justify-content: center;
}

.store-listing {
    text-align: center;
    
}

.title-store-listing {
  font-size: 26px;
  font-family: "Euclid Medium";
  color: #212121;
  margin-left: 150px;
}

.store-listing > .header {
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -30px;
  background: #fff;

}
.sort-after {
    padding-top: 30px;
    padding-right: 12%;
}
.sort-after.mobile {
  padding-top: 0px;
  padding-right: 0;
}
.sort-after-texts {
   cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sort-after-p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #616161;
    font-weight: 500;

}
.sort-after-texts > .sort-after-text-button{
    color: #000;
    font-weight: 500;
    margin-left: 5px;
    margin-right: 2px;
}
.sort-after-container {
    border-radius: 5px;
    width: 150px;
    background: #fafafa;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
    z-index: 10;
   position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sort-after-button {
    padding: none;
    height: 30px;
    width: 150px;
    background: transparent;
    border: none;
}


.items-text{
  font-family: "Suisse Regular";
  font-size: 17px;
  font-weight: 500;
 }

.arrow-down {
    width: 0; 
    height: 0; 

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #616161;
    margin-bottom: 14px;

    
  }

.go-back {
    display: flex;
    flex-direction: row;
}

.back-circle{
    position: fixed;
    margin-top: 10px;
    height: 70px;
    width: 70px;
    margin-left: 40px;
    border-radius: 50%;
    background: white;
    border: 1px SOLID #eeeeee;
    display: flex;
   align-items: center;
   justify-content: center;
  }
  .back-circle img{
    width: 30px;
    max-height: 100%;
    height: auto;
    
  }
  .store-listing p {
    font-size: 12px;
  }
  .store-listing .view-more{
    
    border: 2px solid #F26C25;
    color:#F26C25;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .store-listing .view-more:hover{
    border: 2px solid #F26C25;
    background:#F26C25;
    color:white;
    font-weight: 600;
    
  }
.hidden {
    opacity: 0;
    transition: all 1s;
    
}
.show {
    opacity: 1;
   
}
.content-box {
    transform: translateX(-10%);
}
.content-box.show {
    transform: translateX(0);
}
.home-page-body {
    color: #333;
    font-family: "Euclid Regular";
}

.content-body {
    height: 600px;
  display: flex;
align-items: center;
justify-content: space-between;

}

.no-image-popup{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    background-color: #fff;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    border-radius: 16px;
    padding: 10px;
}
.error-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f44336;
    color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
    
  }
  
  .no-image-popup .error-content {
    text-align: right;
  }
  
  .no-image-popup h6 {
    font-size: 20px;
    margin: 10px 10px 10px 10px;
    text-align: left;
  }
  
  .no-image-popup p {
    font-size: 14px;
    margin-bottom: 20px;
    margin: 10px 10px 10px 10px;
    text-align: left;
  }
  
  .no-image-popup .close-button {
    background-color: #fff;
   
    color: #f44336;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  .no-image-popup .close-button.search {
   color: #1967D2;
  
  }
  .no-image-popup .close-button.search:hover{
    background-color: #1967D2;
   }
  
  
  .no-image-popup .close-button:hover {
    background-color: #e53935;
    color: #fff;
  }

.homepage-header{
   position: fixed;
   display: flex;
   height: 84px;
   align-items: center;
   justify-content: space-between;
   background: #fff;
   width: 100%;
   z-index: 10;
}
.home-logo {
   font-family: "Euclid Medium";
   color: #375BD2;
   font-size: 22px;
   margin-bottom: 0px;
   margin-left: 60px;
}
.menu-buttons{
    display: flex;
    align-items: center;
}

.downolad-extension-button {
    margin-right: 100px;
    font-size: 14px;
    height: 44px;
    width: 200px;
    border-radius: 4px;
    border:2px solid #375BD2;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    background: #fff;
    color: #375BD2;
    font-family: "Euclid Medium";
    display: flex;
    justify-content: center;
    align-items: center;
}
.downolad-extension-button:hover {
background: #F5F7FC;
border:2px solid #1A2B6B;
color: #1A2B6B;

}
.m-button {
font-family: "Euclid Regular";
font-size: 16px;
margin-right: 30px;
color: #222;
}
.m-button:hover {
    color: #375BD2;
    cursor: pointer;
    }
.main-text {
    font-family: "Pangea Regular";
    color: #1A2B6B;
    font-size: 48px;
    padding-top: 100px; 
    padding-bottom: 20px;
}
.second-text {
    color: #3D4556;
    font-size: 16px;
    max-width: 700px;
    font-family: "Euclid Regular";
}
.stats-content {
    display: flex;
    
}
.stats-content p {
    display: flex;
}
.cstat-title{
    display: flex;
    margin-right: 80px;
}

.cstat-title p{
    font-family: "Euclid Medium";
}
.cstat-title .part.one{
    color: #F26C25;
    font-size: 20px;
}
.cstat-title .part.two{
    color: #333;
    font-size: 20px;
    margin-left: 4px;
    
}
.cstat-body{
    font-family: "Euclid Regular";
    display: flex;
    color: #333;
    font-size: 14px;
    width: 180px;
}

.input-field-div{
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;


}

.input-box {
    margin-right: 10px;
width: 640px;
height: 56px;
background: #fff;
/*border: 2px solid #375BD2;*/
border-radius: 4px;
display: flex;
justify-content: space-between;
align-items: center;
box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
}

.content-body-left {
    margin-left: 60px;
}


.input-field-div .input-button-find{
    width: 130px;
    height: 56px;
    background: #375BD2;
    border: 2px solid  #375BD2;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-family: "Euclid Medium";
    margin-right: 10px;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    
}
.input-field-div .input-button-find:hover{
    background: #1A2B6B;
    border: 2px solid  #1A2B6B;
    cursor: pointer;
}
.content-body-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content-body-right .downolad-extension-button {
    margin-top: 10px;
}
.video-on-off{
margin-right: 400px;
margin-bottom: -52px;
margin-top: 20px;
}

.video-on-off-last{
   position: absolute;
   margin-top: 250px;
   margin-left: -50px;
    }

.video-body {
    border-radius: 20px;
    width: 400px;
    height: 330px;
    background: #fff;
    margin-top: 150px;
    margin-right: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 12%);}
.tutorial-video{
    border-radius: 20px;
    height: 100%;
    width: 100%;
}
.ai-search-engine-image {
    height: 350px;
}
.man-shopping {
    height: 250px;
    margin-right: 50px;
}
.content-texts-part{
    margin: 50px 150px 0px 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fadeInRight {
    animation-duration: 1s;
    animation-name: fadeInRightMini;
}
.fadeInLeft {
    animation-duration: 1s;
    animation-name: fadeInLeftMini;
}
@keyframes fadeInRightMini {
   
    0% {
        opacity: 0;
        transform: translate3d(40%,0,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}
@keyframes fadeInLeftMini {
   
    0% {
        opacity: 0;
        transform: translate3d(-40%,0,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}
.content-texts-section{
    margin-top: 150px;
}
.content-texts-part-text {
    width: 500px;
}
.content-texts-part-text h5{
   font-size: 28px;
   font-family: "Euclid SemiBold";
}
.content-texts-part-text p{
    font-size: 16px;
    font-family: "Euclid Regular";
 }
 .input-field-box {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
 }
 .input-field-url {
    height: 40px;
    width: 250px;
    border: none;
    outline: none;
    font-size: 16px;
 }
 .img-upload-button{
    width: 130px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

 }
 .img-upload-button:hover{
color: #375BD2
 }
 .img-upload-button p{
font-size: 14px;
font-family: "Suisse Regular";
   cursor: pointer;
 }
 .search-icon-homepage {
height: 15px;
width: 15px;
margin-right: 10px;
 }
 .photo-icon{
    height: 24px;
width: 24px;
color: #222;
margin-right: 10px;
 }
 .img-icon.homepage{

    background: #fff;
    height:40px;
    width:70px;
    
 }
 .img-icon.homepage .product-image {
    height: 30px;
    width: 30px;
 }
 .nodisplay {
    display: none;
    opacity: 0;
 }
 .info-banners{
    padding-top: 84px;
    display: flex;
    width: 100%;
 }
 
 .banner {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .amount-of-products {
    display: flex;
    width: 70%;
    background: linear-gradient(90deg,#dfe7fb,#F4F7FF);
 }

 .second-informaiton{
    display: flex;
    background: #1A2B6B;
    color: #fff;
    padding: 0;
    margin: 0;
    
 }
 .amount-of-products .text-one-b {
    font-size: 14px;
    margin: 0;
}

.banner.amount-of-products .text-two-b {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 700;
    margin: 0 4px;
 }
 .second-informaiton .text-one-b {
    font-size: 14px;
    margin: 0;

}

.second-informaiton .text-two-b {
    font-size: 14px;
    margin-left: 4px;
    text-decoration: underline;
 }
 .hero-section-background{
    position: absolute;
    overflow: hidden;
    top: 140px;
    z-index: -1;
    margin-top: -2px;
   
 }
 .hero-section-background img {
    width: 100%;
    height: 600px;
  
 }

 .company-logos {
    width: 100%;
    height: 88px;
    margin-top: -2px;
    background:#fff;
    border: 1px solid #E0E6F9;
    display: flex;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 64px;
    align-items: center;
 }
 .grey-promotion-texts{
    font-family: "Euclid Regular";
    color: #5E697B;
    font-size: 18px;
 }
 .hompage-content-boxes{
    display: flex;
    width: 100%;
    height: 350px;
    grid-column-gap: 16px;
    margin-bottom: 100px;
 }
 .content-box{
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 520px;
    padding: 48px 32px;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e7e8ea;
    border-radius: 8px;
    background-color: #fff;
 }
 .content-box-icon{
    display: flex;
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfe7fb;
    border-radius: 28px;
    background-color: #f5f7fd;
 }
 .box-subtitle{
    font-family: "Roboto", sans-serif;
    color: #202124;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
 }
 .content-box-content {
    color: #6D7380;
    font-size: 14px;
    font-weight: 500;
 }
 .content-subject-title{ 
    font-family: "Google sans Regular";
    color: #202124;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-bottom: 100px;
 }

 @media (max-width: 500px) {
    .content-subject-title{      
        font-size: 30px;
        line-height: normal;  
        margin-bottom: 100px;
     }
  }
 

 .content-box-icon img{
    border: 0;
    width: 30px;
    height: 30px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
 }
 img.extension-icon-image {
    width: 26px;
    height: 26px;
    margin-left: 2px;
 }
 .headerbutton {
    box-shadow: none;
 }
 .filled-button{
    width: 240px;
    height: 56px;
    background: #375BD2;
    border: 2px solid  #375BD2;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-family: "Euclid Regular";
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.last-download-section {
    margin-top: 50px;
}
.last-download-section button{
    width: 240px;
    height: 44px;
    margin: 0;

}
.two-buttons-bottom{
    display: flex;
    justify-content: center;
    grid-column-gap: 20px;
    margin-bottom: 100px;
}
.tutorial-video-second {
    width: 520px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.about-us-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    padding-left: 50px;
    margin-bottom: 200px;
}

.about-us-title{
padding-top: 200px;

}
.about-us-content-body{
   max-width: 700px;
   font-size: 16px;
}

.searchbyimage-video-body {
    border-radius: 8px;
max-width: 450px;
max-height: 450px;
box-shadow: 0 5px 5px 0 rgba(154,160,185,.05), 0 5px 30px 0 rgba(166,173,201,.22);
}
.tutorial-video-searchby {
    border-radius: 8px;
    height: 100%;
    width: 100%;
}
.show-search-by-image{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 150px;
    margin-bottom: 150px;
  
}
.left-serchbyimage-content{

}
.serchbyimage-content-title{
    color: #202124;
    font-size: 30px;
    font-family: "Google sans Regular";
    letter-spacing: 0.1px;
}
.serchbyimage-content-text{
    color: #6D7380
}
.video-on-off-searchbyimage{
    position: absolute;
    margin-left: -425px;
    margin-top: 400px;
    
}



/*mobile css */

.mobile-body{
    background: #EEF1FD;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-front-page-header {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
}
.mobile-menu-button {
   background: transparent;
   border: none;
   margin-right: 10px;
    }
.mobile-menu-button img{
width: 28px;
height: 28px;
margin-right: 10px;
}
.mobile-logo{
    all:unset;
    font-size: 24px;
    color: #000;
    font-family:"Pangea Medium";
    margin-left: 20px;
    

}

.mobile-content-body{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile-title{
    font-size: 36px;
    color: #212121;
    font-family:"Pangea Medium";
    font-weight: 300;
    text-align: center;
    line-height: 44px;
    max-width: 400px;
    padding: 0px 16px;
    margin: 0;
    
}
.mobile-content-text{
    font-size: 16px;
    color: #424242;
    font-family:"Suisse Regular";
    font-weight: 300;
    text-align: center;
    max-width: 428px;
    line-height: 24px;
    padding: 16px 16px 0px;
    margin: 10px 0 25px 0 ;
}
.mobile-features{
    display: flex;
}
.mobile-features .stat-text{
    font-size: 14px;
    color: #1967D2;
    font-family:"Suisse Bold";
    text-align: center;
    line-height: 24px;
    margin-left: 2px;
    margin-right: 2px;
}
.mobile-features .normal-text{
    font-size: 14px;
    color: #424242;
    font-family:"Suisse Regular";
    text-align: center;
    line-height: 24px;
    margin-left: 2px;
    margin-right: 2px;
 
}
.mobile-menu-button img{
    width: 28px;
    height: 28px;
    margin-right: 10px;
    }
.add-to-phone-button{
    margin: 40px 0 40px 0;
    width: 240px;
    height: 48px;
    background: #375BD2;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    font-family:"Suisse Regular";
    text-align: center;
    margin-bottom: 100px;
}

.phone-emu{
    width: 300px;
    border-radius: 40px 40px 0 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.greyspots{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.grey-dot{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background:#F2F2F2;
    margin-right: 10px;
}
.grey-rect{
    height: 6px;
    width: 70px;
    border-radius: 40px;
    background:#F2F2F2;
}

.mobile-tutorial-video {
max-width: 290px;
margin-top: 30px;

margin-bottom: 20px;
}

.mobile-text-content{
    width: 100%;
    background: #fff;
    padding-top: 80px;
}

.mobile-content-title{
    font-size: 36px;
    color: #212121;
    font-family:"Pangea Medium";
    font-weight: 300;
    text-align: center;
    line-height: 44px;
    max-width: 400px;
    padding: 0px 16px;
    margin-bottom: 50px;
}
.mobile-content-div:nth-child(2) {
    margin-top: 100px
}

.add-to-phone-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 50px 0
}

.add-appstore-button img{
    height: 10px;
}
.mobile-video-border-div {
    position: absolute;
    margin-top: 60px;
    width: 300px;
    height: 400px;
     background: transparent;
    border: 10px solid white;

}

.suggestions-container{
    position: absolute;
    /* height: 150px; */

    z-index: 100;
    margin-top: -100px;
    width: 520px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,.1);
    background: white;
}
.input-suggestion{
    background: white;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    font-family: "Google sans Regular";
}

.input-suggestion:hover{
    background:rgba(0,0,0,0.05)
}

.country-input{
    width: 130px;
    height: 50px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
cursor: pointer;


}
.country-input:hover{
color: #375BD2;
}
.country-select-icon{
    height: 11px;
    width: 11px;
    margin-left: 4px;
    margin-bottom: 1px;
}
.country-input-text{
    font-size: 14px;
    font-family: "Suisse Regular";
}
.country-input-text {
    font-size: 14px;
    font-family: "Suisse Regular";
}

.input-write-cointainer{
    display: flex;
align-items: center;


}
.field-info-inputs{
    display: flex;
    align-items: center;

}
.search-buttons-divider-line{
    width: 2px;
    height: 32px;
    background: #E7E7E9;
}

.menu-item-country{
    font-size: 14px;
    font-family: "Suisse Regular";
  

}
.select-country-div{
    font-size: 14px;
    font-family: "Suisse Regular";

    width: 120px;
    border: none;
    height: 50px;
}

.select-country-div:hover{
  color: #375BD2;
}

.new-header{
    z-index: 100;
    width: 100%;
    position: fixed;
    height: 88px;
    background: #fff;
    border-bottom: 1px solid #DADCE0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-header-wraper-mobile{
    z-index: 100;
    height:126px;
    background: #fff;
    position: fixed;
    width: 100%;
   display: flex;
   flex-direction: column;
}


.new-header-mobile{
    position: relative;
    height: 70px;
    width: 100%;
    background: #fff;  
     border-bottom: none;
     display: flex;
     align-items: center;
     justify-content: space-between;
   }

.new-logo{
    color: #1967D2;
    font-size: 24px;
    font-family: "Google Sans Regular";
    margin: 0;
    margin-left: 50px;
    cursor: pointer;
}
.new-input-search-box{
   width: 540px;
   height: 47px;
   border: 1px solid rgb(218,220,224);
   border-radius: 22px;
   display: flex;
   align-items: center;
}
.new-input-search-box.mobile {
    width: 100%;
}
.new-search-icon{
    height: 15px;
    margin-left: 15px;
    margin-right: 10px;
}

.new-input-field-url {
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Google sans Regular";
 }
 .new-input-field-url::-webkit-input-placeholder { /* Most modern browsers support this now. */
    color:    rgba(0, 0, 0, 0.6);
 }
 .new-input-field-url:-ms-input-placeholder { /* Most modern browsers support this now. */
    color:    rgba(0, 0, 0, 0.6);
 }
 .new-input-field-url::placeholder { /* Most modern browsers support this now. */
    color:    rgba(0, 0, 0, 0.6);
 }
 .new-herosection{
    
    padding-top: 15%;
    display: flex;
  
    justify-content: space-between;
    margin-left: 90px;
    margin-right: 110px;
 }
 .new-herosection.mobile{
    padding-top: 130px;
    display: flex;
    margin-left: 0;
    margin-right: 0;
 }


 .computer-image{
    height: 300px;
 }
 .herosection-text{
    color: #202124;
    font-size: 32px;
    font-family: "Google Sans Light";
    padding: 0;
    margin: 0;
   
 }
 .new-download-extension{
    margin-right: 55px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-family: "Google Sans Medium";
  
 }
 .products-suggestion-title{
    color: #202124;
    font-size: 24px;
    font-family: "Google Sans Light";
    margin-bottom: 30px;
 }
 
 .products-suggestions{
    width:calc(100% - 176px);
    margin-right: 88px;
    margin-left: 88px;
    margin-top: 00px;
 }
 .products-suggestions.mobile{
    width:calc(100% - 20px);
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 40px;
 
 }
.products-suggestion-products{
  
    display: grid;
 grid-template-columns: repeat(auto-fill, minmax(calc(max(9rem + 1rem, 100% / 6)), 1fr));
 margin-bottom: -1.5rem;
 margin-right: -1rem;
}

.products-suggestion-products.mobile{
  
    display: grid;
 grid-template-columns: repeat(auto-fill, minmax(calc(max(9rem + 0.5rem, 100% / 2)), 1fr));
 margin-bottom: -1.5rem;
 margin-right: -1rem;
}
.suggestions-products{
    
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    background: #fff;
}
.suggestion-image-frame{
    aspect-ratio: 1 / 1;
    background: rgba(32,33,36,0.04);
width: 100%;
padding: 1rem;
border-radius: 8px;
display: flex;
justify-content: center;
}
.suggestion-image{
    height: 100%;
    mix-blend-mode: darken;

}
.suggestion-product-price{
    color: #202124;
    font-size: 16px;
    font-family: "Google Sans Bold";
    margin-top: 8px;
}
.suggestion-product-title{
    color: rgb(95,99,104);
    font-size: 16px;
    font-family: "Google Sans Regular";
    margin-top: 4px;

}
.view-more-home-page{
    color: rgb(26,115,232);
    background: white;
    border: none;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border-radius: 24px;
    font-family: "Google sans Regular";
    font-size: 14px;
    letter-spacing: .0107142857em;
    font-weight: 500;
    text-transform: none;
}
.view-more-home-page:hover{
    color: rgb(26,115,232);
    background: #F7FAFE;
}
.react-select-flags-homepage{
    border: none;
}
#rfs-btn{
    border: none; 
}

.new-search-field{
    position: relative;
}
.new-search-field.mobile{
    width: 90%;
}
.new-suggestions{


 width: 540px;
    background: #fff;
    position: absolute;
    text-align: left;
    z-index: 110;
    overflow: hidden;
    cursor: default;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: -1px;
    border: 1px solid #ccc;
    border-radius: 0 0 20px 20px;
    border-top-color: #d9d9d9;

    border-top: 2px solid rgb(232,234,237);
    border-color: rgb(232,234,237);
    
}

  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #fff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    z-index: 10;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
    width: 100%;
  
  }
  
  .nav-text {
    display: flex;

    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #616161;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  
  
  .nav-menu-items {
    width: 100%;
  }

  .menu-bars img{
    width: 28px;
    height: 28px;
    margin-right: 10px;
    }
  
  .navbar-toggle {
    background-color: #fff;
    width: 100%;
    height: 80px;
    display: flex;

    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
.contact-form-main {
    align-items: center;
    display: flex;
flex-direction: column;
}
.support-title{
    padding-top: 50px;
}
.faq-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}

.faq-questions {
    max-width: 600px;
    width: 80%;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #1A2B6B;;
   
    cursor: pointer;
}
.faq-q {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faq-q-title {
    color: #1A2B6B;
    font-size: 1.8rem;
    line-height: normal;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.faq-answer p {
    padding-top: 1rem;
    line-height: 1.6;
    font-size: 1.2rem;
    color: #6D7380;
}

.faq-questions.active .faq-answer {
    max-height: 300px;
    transition: max-height 1.4s ease;
}


.faq-questions.active svg{
    transform: rotate(180deg);
 
}
svg{
    transition: transform 0.5s ease-in;
}
.cTRFnb{
    max-width: 500px;
    width: 90% !important;
}

.tutorial-how-to-use{
    max-width: 520px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 40px;
}
 .content-box {
max-width: 600px;
width: 90%;
display: flex;
margin-bottom: 20px;
margin-left: 10px;
margin-right: 10px;
transform: translate(0);
}
.howtouse{
    font-size: 20px;
    margin-bottom: 20px;
}
.how-to-use-content-boxes {
    display: flex;

}

@media (max-width: 1000px) {
    .how-to-use-content-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  }

.howitworks-body{
    flex-direction: column;
 display: flex;
 align-items: center; 
}
  .howitworks-all{
 width: 80%;
 flex-direction: column;
 display: flex;
 align-items: center;
 margin-bottom: 200px;
  }

  .howitworks-title-content{
    width: 100%;
    margin-top: 100px;
    
  }

  .howitworks-title-main{
    font-family: "Pangea Medium";
    font-size: 40px;
    line-height: 80px;
  }

  .howitworks-title-subtitle{
    font-family: "Pangea Medium";
    font-size: 20px;
  }

  .howitworks-content {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .howitworks-instructions{
    display: flex;
  }
 
.howitworks-text-content{
    margin: 3px 0 0 49px;
}

.howitworks-instruction-content{
    width: 400px;
  margin-bottom: 80px;
}

.howitworks-instruction-content:last-child{
    margin-bottom: 0;
} 
 
.howitworks-instruction-title{
    font-size: 20px;
    font-family: "Suisse Regular";
    color: #9e9e9e;
    cursor: default;
}
.howitworks-instruction-title.current{
    color: #212121;
}
.howitworks-instruction-explanation{
    margin-top: 10px;
    font-size: 16px;
    font-family: "Suisse Regular";
    color: #757575;
}
.howitworks-next-button{
    width: 100px;
    height: 40px;
    background: #1967D2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Suisse Regular";
    font-size: 14px;
    border-radius: 4px;
    margin-top: 20px;
    cursor: default;
}

.howitworks-progressbar{
    height: 370px;
    width: 1px;
    background: #bdbdbd;
 
    
  }
.howitworks-progressbar-blue{
    background: #1967D2;
    width: 3px;
    margin-left: -1px;
    height: 50px;
}


.howitworks-progressbar-blue.step2{
    margin-top: 100px;
}
.howitworks-progressbar-blue.step3{
    margin-top: 200px;
}
.howitworks-video{
    max-height: 380px;
}
.tutorial-howtouse{
    box-shadow: 0 5px 5px 0 rgba(154,160,185,.05), 0 5px 30px 0 rgba(166,173,201,.22);
    border-radius: 4px;
    width: 100%;
    max-height: 380px;
}
.features-body {
padding-top: 150px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.features-content-boxes .content-box {
margin-bottom: 20px;
}

.pc-main-product-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 70px;
    height: calc(100vh - 70px) ;

}
.pc-image-div{
    width: 420px;
   
}
.pc-image {
max-width: 375px;
max-height: 375px;
transition: transform 0.3s ease-in-out;
}


.pc-image:hover {

   

    transform: scale(1.2); /* Enlarge the image on hover */
  
}
    

.pc-product-info{
    max-width: 420px;

}
.pc-main-title{
    font-size: 30px;
   font-family: "Suisse Medium";
   font-weight: 500;
   color:black

}
.best-price-label{
    font-size: 16px;
    font-family: "Suisse Regular";
    color:#757575;
    margin: 0 10px 20px 0px;
}
.pc-main-price{
    display: flex;
    align-items: center;
}
.pc-big-price {
    font-size: 36px;
    font-family: "Suisse Bold";
    color:#212121;
    margin-right: 10px;
    margin-top: 4px;
}
.pc-price-before{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
}
.pc-price-saved {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
}
.pc-main-buy-button{
    margin-top:30px;
    border:none;
    background: #1967D2;
    color: #fff;
    width: 280px;
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
    font-family: "Suisse Medium";
}
.pc-stores-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}
.pc-amountofstores{
    font-size: 24px;
    font-family: "Suisse Medium";
    color:#212121;
    margin-left: 80px;
    width: 100%;

}
.pc-lowest-price{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#212121;
    width: 100%;
    text-align: center;

}

.pc-condition{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#212121;
    width: 100%;
    text-align: center;
}

.stores-paddingdiv{
    min-width: 290px;
}

.pc-stores-listing{
    border-top: 1px solid #e0e0e0;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pc-store-data{
    display: flex;
    align-items: center;
    width: 100%;
    margin-right:80px;

}
.pc-store-circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px 0px 60px;
}
.pc-store-name {
    display: flex;
    align-items: center;
}
.pc-amountOnStore{
    font-size: 11px;
    background: #222;
    color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 7px;
    margin-top: 4px;
    padding: 0px 7px;
    font-family: "Montserrat";
}
.pc-store-name {
    display: flex;
}
.pc-store-name .p{
    font-family: "Suisse Regular";
    font-size: 20px;
    font-weight: 500;
    color:#212121;
}
.pc-store-trusted{
    font-family: "Suisse Regular";
    font-size: 12px;
    font-weight: 500;
    color:#212121; 
}

.pc-listing-product-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.pc-listing-image{
    max-width: 70px;
    max-height: 70px;
    margin-right: 30px;
}

.pc-listing-price{
    font-family: "Suisse Medium";
    font-size: 20px;
    font-weight: 500;
    color:#212121;
    margin-right: 10px;
}

.pc-listing-save{
    
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    color:#757575;
}

.pc-listing-condition {
    display: flex;
    justify-content: center;
    width: 100%;

}
.pc-listing-condition p{
    font-family: "Suisse Regular";
    font-size: 16px;
    font-weight: 500;
    color:#212121;

}

.pc-visit-site-button{
    border: 1px solid #424242;
    background: transparent;
    max-width: 240px;
    width: 100%;
    height: 50px;
    font-family: "Suisse Regular";
    font-size: 16px;
    color:#424242;
    border-radius: 4px;
    margin-right: 50px;

}

.loading-heart-body{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-heart{
    height: 35px;
    margin-right: 20px;
    margin-top: 4px;
    animation: beat 1.5s infinite ease-in-out;
}
@keyframes beat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
.loading-heart-text{
    font-size: 24px;
    color: #424242;
    font-weight: 400;
    font-family: "Montserrat";
}
