.hidden {
    opacity: 0;
    transition: all 1s;
    
}
.show {
    opacity: 1;
   
}
.content-box {
    transform: translateX(-10%);
}
.content-box.show {
    transform: translateX(0);
}
.home-page-body {
    color: #333;
    font-family: "Euclid Regular";
}

.content-body {
    height: 600px;
  display: flex;
align-items: center;
justify-content: space-between;

}

.no-image-popup{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    background-color: #fff;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    border-radius: 16px;
    padding: 10px;
}
.error-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f44336;
    color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
    
  }
  
  .no-image-popup .error-content {
    text-align: right;
  }
  
  .no-image-popup h6 {
    font-size: 20px;
    margin: 10px 10px 10px 10px;
    text-align: left;
  }
  
  .no-image-popup p {
    font-size: 14px;
    margin-bottom: 20px;
    margin: 10px 10px 10px 10px;
    text-align: left;
  }
  
  .no-image-popup .close-button {
    background-color: #fff;
   
    color: #f44336;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  .no-image-popup .close-button.search {
   color: #1967D2;
  
  }
  .no-image-popup .close-button.search:hover{
    background-color: #1967D2;
   }
  
  
  .no-image-popup .close-button:hover {
    background-color: #e53935;
    color: #fff;
  }

.homepage-header{
   position: fixed;
   display: flex;
   height: 84px;
   align-items: center;
   justify-content: space-between;
   background: #fff;
   width: 100%;
   z-index: 10;
}
.home-logo {
   font-family: "Euclid Medium";
   color: #375BD2;
   font-size: 22px;
   margin-bottom: 0px;
   margin-left: 60px;
}
.menu-buttons{
    display: flex;
    align-items: center;
}

.downolad-extension-button {
    margin-right: 100px;
    font-size: 14px;
    height: 44px;
    width: 200px;
    border-radius: 4px;
    border:2px solid #375BD2;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    background: #fff;
    color: #375BD2;
    font-family: "Euclid Medium";
    display: flex;
    justify-content: center;
    align-items: center;
}
.downolad-extension-button:hover {
background: #F5F7FC;
border:2px solid #1A2B6B;
color: #1A2B6B;

}
.m-button {
font-family: "Euclid Regular";
font-size: 16px;
margin-right: 30px;
color: #222;
}
.m-button:hover {
    color: #375BD2;
    cursor: pointer;
    }
.main-text {
    font-family: "Pangea Regular";
    color: #1A2B6B;
    font-size: 48px;
    padding-top: 100px; 
    padding-bottom: 20px;
}
.second-text {
    color: #3D4556;
    font-size: 16px;
    max-width: 700px;
    font-family: "Euclid Regular";
}
.stats-content {
    display: flex;
    
}
.stats-content p {
    display: flex;
}
.cstat-title{
    display: flex;
    margin-right: 80px;
}

.cstat-title p{
    font-family: "Euclid Medium";
}
.cstat-title .part.one{
    color: #F26C25;
    font-size: 20px;
}
.cstat-title .part.two{
    color: #333;
    font-size: 20px;
    margin-left: 4px;
    
}
.cstat-body{
    font-family: "Euclid Regular";
    display: flex;
    color: #333;
    font-size: 14px;
    width: 180px;
}

.input-field-div{
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;


}

.input-box {
    margin-right: 10px;
width: 640px;
height: 56px;
background: #fff;
/*border: 2px solid #375BD2;*/
border-radius: 4px;
display: flex;
justify-content: space-between;
align-items: center;
box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
}

.content-body-left {
    margin-left: 60px;
}


.input-field-div .input-button-find{
    width: 130px;
    height: 56px;
    background: #375BD2;
    border: 2px solid  #375BD2;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-family: "Euclid Medium";
    margin-right: 10px;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    
}
.input-field-div .input-button-find:hover{
    background: #1A2B6B;
    border: 2px solid  #1A2B6B;
    cursor: pointer;
}
.content-body-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content-body-right .downolad-extension-button {
    margin-top: 10px;
}
.video-on-off{
margin-right: 400px;
margin-bottom: -52px;
margin-top: 20px;
}

.video-on-off-last{
   position: absolute;
   margin-top: 250px;
   margin-left: -50px;
    }

.video-body {
    border-radius: 20px;
    width: 400px;
    height: 330px;
    background: #fff;
    margin-top: 150px;
    margin-right: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 12%);}
.tutorial-video{
    border-radius: 20px;
    height: 100%;
    width: 100%;
}
.ai-search-engine-image {
    height: 350px;
}
.man-shopping {
    height: 250px;
    margin-right: 50px;
}
.content-texts-part{
    margin: 50px 150px 0px 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fadeInRight {
    animation-duration: 1s;
    animation-name: fadeInRightMini;
}
.fadeInLeft {
    animation-duration: 1s;
    animation-name: fadeInLeftMini;
}
@keyframes fadeInRightMini {
   
    0% {
        opacity: 0;
        transform: translate3d(40%,0,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}
@keyframes fadeInLeftMini {
   
    0% {
        opacity: 0;
        transform: translate3d(-40%,0,0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}
.content-texts-section{
    margin-top: 150px;
}
.content-texts-part-text {
    width: 500px;
}
.content-texts-part-text h5{
   font-size: 28px;
   font-family: "Euclid SemiBold";
}
.content-texts-part-text p{
    font-size: 16px;
    font-family: "Euclid Regular";
 }
 .input-field-box {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
 }
 .input-field-url {
    height: 40px;
    width: 250px;
    border: none;
    outline: none;
    font-size: 16px;
 }
 .img-upload-button{
    width: 130px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

 }
 .img-upload-button:hover{
color: #375BD2
 }
 .img-upload-button p{
font-size: 14px;
font-family: "Suisse Regular";
   cursor: pointer;
 }
 .search-icon-homepage {
height: 15px;
width: 15px;
margin-right: 10px;
 }
 .photo-icon{
    height: 24px;
width: 24px;
color: #222;
margin-right: 10px;
 }
 .img-icon.homepage{

    background: #fff;
    height:40px;
    width:70px;
    
 }
 .img-icon.homepage .product-image {
    height: 30px;
    width: 30px;
 }
 .nodisplay {
    display: none;
    opacity: 0;
 }
 .info-banners{
    padding-top: 84px;
    display: flex;
    width: 100%;
 }
 
 .banner {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .amount-of-products {
    display: flex;
    width: 70%;
    background: linear-gradient(90deg,#dfe7fb,#F4F7FF);
 }

 .second-informaiton{
    display: flex;
    background: #1A2B6B;
    color: #fff;
    padding: 0;
    margin: 0;
    
 }
 .amount-of-products .text-one-b {
    font-size: 14px;
    margin: 0;
}

.banner.amount-of-products .text-two-b {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 700;
    margin: 0 4px;
 }
 .second-informaiton .text-one-b {
    font-size: 14px;
    margin: 0;

}

.second-informaiton .text-two-b {
    font-size: 14px;
    margin-left: 4px;
    text-decoration: underline;
 }
 .hero-section-background{
    position: absolute;
    overflow: hidden;
    top: 140px;
    z-index: -1;
    margin-top: -2px;
   
 }
 .hero-section-background img {
    width: 100%;
    height: 600px;
  
 }

 .company-logos {
    width: 100%;
    height: 88px;
    margin-top: -2px;
    background:#fff;
    border: 1px solid #E0E6F9;
    display: flex;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 64px;
    align-items: center;
 }
 .grey-promotion-texts{
    font-family: "Euclid Regular";
    color: #5E697B;
    font-size: 18px;
 }
 .hompage-content-boxes{
    display: flex;
    width: 100%;
    height: 350px;
    grid-column-gap: 16px;
    margin-bottom: 100px;
 }
 .content-box{
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 520px;
    padding: 48px 32px;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e7e8ea;
    border-radius: 8px;
    background-color: #fff;
 }
 .content-box-icon{
    display: flex;
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfe7fb;
    border-radius: 28px;
    background-color: #f5f7fd;
 }
 .box-subtitle{
    font-family: "Roboto", sans-serif;
    color: #202124;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
 }
 .content-box-content {
    color: #6D7380;
    font-size: 14px;
    font-weight: 500;
 }
 .content-subject-title{ 
    font-family: "Google sans Regular";
    color: #202124;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-bottom: 100px;
 }

 @media (max-width: 500px) {
    .content-subject-title{      
        font-size: 30px;
        line-height: normal;  
        margin-bottom: 100px;
     }
  }
 

 .content-box-icon img{
    border: 0;
    width: 30px;
    height: 30px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
 }
 img.extension-icon-image {
    width: 26px;
    height: 26px;
    margin-left: 2px;
 }
 .headerbutton {
    box-shadow: none;
 }
 .filled-button{
    width: 240px;
    height: 56px;
    background: #375BD2;
    border: 2px solid  #375BD2;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-family: "Euclid Regular";
    box-shadow: 0 12px 32px -12px rgb(12 22 44 / 32%);
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.last-download-section {
    margin-top: 50px;
}
.last-download-section button{
    width: 240px;
    height: 44px;
    margin: 0;

}
.two-buttons-bottom{
    display: flex;
    justify-content: center;
    grid-column-gap: 20px;
    margin-bottom: 100px;
}
.tutorial-video-second {
    width: 520px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.about-us-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    padding-left: 50px;
    margin-bottom: 200px;
}

.about-us-title{
padding-top: 200px;

}
.about-us-content-body{
   max-width: 700px;
   font-size: 16px;
}

.searchbyimage-video-body {
    border-radius: 8px;
max-width: 450px;
max-height: 450px;
box-shadow: 0 5px 5px 0 rgba(154,160,185,.05), 0 5px 30px 0 rgba(166,173,201,.22);
}
.tutorial-video-searchby {
    border-radius: 8px;
    height: 100%;
    width: 100%;
}
.show-search-by-image{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 150px;
    margin-bottom: 150px;
  
}
.left-serchbyimage-content{

}
.serchbyimage-content-title{
    color: #202124;
    font-size: 30px;
    font-family: "Google sans Regular";
    letter-spacing: 0.1px;
}
.serchbyimage-content-text{
    color: #6D7380
}
.video-on-off-searchbyimage{
    position: absolute;
    margin-left: -425px;
    margin-top: 400px;
    
}



/*mobile css */

.mobile-body{
    background: #EEF1FD;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-front-page-header {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
}
.mobile-menu-button {
   background: transparent;
   border: none;
   margin-right: 10px;
    }
.mobile-menu-button img{
width: 28px;
height: 28px;
margin-right: 10px;
}
.mobile-logo{
    all:unset;
    font-size: 24px;
    color: #000;
    font-family:"Pangea Medium";
    margin-left: 20px;
    

}

.mobile-content-body{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile-title{
    font-size: 36px;
    color: #212121;
    font-family:"Pangea Medium";
    font-weight: 300;
    text-align: center;
    line-height: 44px;
    max-width: 400px;
    padding: 0px 16px;
    margin: 0;
    
}
.mobile-content-text{
    font-size: 16px;
    color: #424242;
    font-family:"Suisse Regular";
    font-weight: 300;
    text-align: center;
    max-width: 428px;
    line-height: 24px;
    padding: 16px 16px 0px;
    margin: 10px 0 25px 0 ;
}
.mobile-features{
    display: flex;
}
.mobile-features .stat-text{
    font-size: 14px;
    color: #1967D2;
    font-family:"Suisse Bold";
    text-align: center;
    line-height: 24px;
    margin-left: 2px;
    margin-right: 2px;
}
.mobile-features .normal-text{
    font-size: 14px;
    color: #424242;
    font-family:"Suisse Regular";
    text-align: center;
    line-height: 24px;
    margin-left: 2px;
    margin-right: 2px;
 
}
.mobile-menu-button img{
    width: 28px;
    height: 28px;
    margin-right: 10px;
    }
.add-to-phone-button{
    margin: 40px 0 40px 0;
    width: 240px;
    height: 48px;
    background: #375BD2;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    font-family:"Suisse Regular";
    text-align: center;
    margin-bottom: 100px;
}

.phone-emu{
    width: 300px;
    border-radius: 40px 40px 0 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.greyspots{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.grey-dot{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background:#F2F2F2;
    margin-right: 10px;
}
.grey-rect{
    height: 6px;
    width: 70px;
    border-radius: 40px;
    background:#F2F2F2;
}

.mobile-tutorial-video {
max-width: 290px;
margin-top: 30px;

margin-bottom: 20px;
}

.mobile-text-content{
    width: 100%;
    background: #fff;
    padding-top: 80px;
}

.mobile-content-title{
    font-size: 36px;
    color: #212121;
    font-family:"Pangea Medium";
    font-weight: 300;
    text-align: center;
    line-height: 44px;
    max-width: 400px;
    padding: 0px 16px;
    margin-bottom: 50px;
}
.mobile-content-div:nth-child(2) {
    margin-top: 100px
}

.add-to-phone-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 50px 0
}

.add-appstore-button img{
    height: 10px;
}
.mobile-video-border-div {
    position: absolute;
    margin-top: 60px;
    width: 300px;
    height: 400px;
     background: transparent;
    border: 10px solid white;

}

.suggestions-container{
    position: absolute;
    /* height: 150px; */

    z-index: 100;
    margin-top: -100px;
    width: 520px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,.1);
    background: white;
}
.input-suggestion{
    background: white;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    font-family: "Google sans Regular";
}

.input-suggestion:hover{
    background:rgba(0,0,0,0.05)
}

.country-input{
    width: 130px;
    height: 50px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
cursor: pointer;


}
.country-input:hover{
color: #375BD2;
}
.country-select-icon{
    height: 11px;
    width: 11px;
    margin-left: 4px;
    margin-bottom: 1px;
}
.country-input-text{
    font-size: 14px;
    font-family: "Suisse Regular";
}
.country-input-text {
    font-size: 14px;
    font-family: "Suisse Regular";
}

.input-write-cointainer{
    display: flex;
align-items: center;


}
.field-info-inputs{
    display: flex;
    align-items: center;

}
.search-buttons-divider-line{
    width: 2px;
    height: 32px;
    background: #E7E7E9;
}

.menu-item-country{
    font-size: 14px;
    font-family: "Suisse Regular";
  

}
.select-country-div{
    font-size: 14px;
    font-family: "Suisse Regular";

    width: 120px;
    border: none;
    height: 50px;
}

.select-country-div:hover{
  color: #375BD2;
}

.new-header{
    z-index: 100;
    width: 100%;
    position: fixed;
    height: 88px;
    background: #fff;
    border-bottom: 1px solid #DADCE0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-header-wraper-mobile{
    z-index: 100;
    height:126px;
    background: #fff;
    position: fixed;
    width: 100%;
   display: flex;
   flex-direction: column;
}


.new-header-mobile{
    position: relative;
    height: 70px;
    width: 100%;
    background: #fff;  
     border-bottom: none;
     display: flex;
     align-items: center;
     justify-content: space-between;
   }

.new-logo{
    color: #1967D2;
    font-size: 24px;
    font-family: "Google Sans Regular";
    margin: 0;
    margin-left: 50px;
    cursor: pointer;
}
.new-input-search-box{
   width: 540px;
   height: 47px;
   border: 1px solid rgb(218,220,224);
   border-radius: 22px;
   display: flex;
   align-items: center;
}
.new-input-search-box.mobile {
    width: 100%;
}
.new-search-icon{
    height: 15px;
    margin-left: 15px;
    margin-right: 10px;
}

.new-input-field-url {
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Google sans Regular";
 }
 .new-input-field-url::placeholder { /* Most modern browsers support this now. */
    color:    rgba(0, 0, 0, 0.6);
 }
 .new-herosection{
    
    padding-top: 15%;
    display: flex;
  
    justify-content: space-between;
    margin-left: 90px;
    margin-right: 110px;
 }
 .new-herosection.mobile{
    padding-top: 130px;
    display: flex;
    margin-left: 0;
    margin-right: 0;
 }


 .computer-image{
    height: 300px;
 }
 .herosection-text{
    color: #202124;
    font-size: 32px;
    font-family: "Google Sans Light";
    padding: 0;
    margin: 0;
   
 }
 .new-download-extension{
    margin-right: 55px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-family: "Google Sans Medium";
  
 }
 .products-suggestion-title{
    color: #202124;
    font-size: 24px;
    font-family: "Google Sans Light";
    margin-bottom: 30px;
 }
 
 .products-suggestions{
    width:calc(100% - 176px);
    margin-right: 88px;
    margin-left: 88px;
    margin-top: 00px;
 }
 .products-suggestions.mobile{
    width:calc(100% - 20px);
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 40px;
 
 }
.products-suggestion-products{
  
    display: grid;
 grid-template-columns: repeat(auto-fill, minmax(calc(max(9rem + 1rem, 100% / 6)), 1fr));
 margin-bottom: -1.5rem;
 margin-right: -1rem;
}

.products-suggestion-products.mobile{
  
    display: grid;
 grid-template-columns: repeat(auto-fill, minmax(calc(max(9rem + 0.5rem, 100% / 2)), 1fr));
 margin-bottom: -1.5rem;
 margin-right: -1rem;
}
.suggestions-products{
    
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    background: #fff;
}
.suggestion-image-frame{
    aspect-ratio: 1 / 1;
    background: rgba(32,33,36,0.04);
width: 100%;
padding: 1rem;
border-radius: 8px;
display: flex;
justify-content: center;
}
.suggestion-image{
    height: 100%;
    mix-blend-mode: darken;

}
.suggestion-product-price{
    color: #202124;
    font-size: 16px;
    font-family: "Google Sans Bold";
    margin-top: 8px;
}
.suggestion-product-title{
    color: rgb(95,99,104);
    font-size: 16px;
    font-family: "Google Sans Regular";
    margin-top: 4px;

}
.view-more-home-page{
    color: rgb(26,115,232);
    background: white;
    border: none;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border-radius: 24px;
    font-family: "Google sans Regular";
    font-size: 14px;
    letter-spacing: .0107142857em;
    font-weight: 500;
    text-transform: none;
}
.view-more-home-page:hover{
    color: rgb(26,115,232);
    background: #F7FAFE;
}
.react-select-flags-homepage{
    border: none;
}
#rfs-btn{
    border: none; 
}

.new-search-field{
    position: relative;
}
.new-search-field.mobile{
    width: 90%;
}
.new-suggestions{


 width: 540px;
    background: #fff;
    position: absolute;
    text-align: left;
    z-index: 110;
    overflow: hidden;
    cursor: default;
    user-select: none;
    margin-top: -1px;
    border: 1px solid #ccc;
    border-radius: 0 0 20px 20px;
    border-top-color: #d9d9d9;

    border-top: 2px solid rgb(232,234,237);
    border-color: rgb(232,234,237);
    
}