
.slider-div {
margin-left: 50px;
background: transparent;
margin-bottom: 40px;
}

.card-item {
   margin-bottom: 10px;
width: 100%;
  
}
.store-card {
    position: relative;
    margin: -12px 0 20px 20px;
    border:1px solid #e0e0e0;
    height: 160px;
    width: 95%;
    border-radius: 10px;
    background: #fff;
}
.store-product-image {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    height: 220px;
    width: 220px;
    margin: 20px 2px 0px 22px;
    width: calc(95% - 4px) ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.store-product-image img{
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    
}
.store-title-header{
    display: flex;
    margin-top: 8px;
}

.store-logo {
    height:42px;
    width:42px;
    border-radius: 50%;
    margin: 0px 0px 0px 15px;
    }
.store-title-header .store-header-text p{
    margin: 0px 0px 0px 10px;
    text-align: left;
}
.store-title-header .store-header-text .store-company-name{
    font-family: "Roboto";
    font-size: 17px;
    font-weight: 500;
    color: #212121;
}
.store-title-header .store-header-text .store-potentially-cheaper{
    margin-top: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #212121;
}
.store-product-title {
    margin-top: 10px;
    margin-left:24px;
    font-family: "Roboto";
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    height: 45px;
}
.store-action-buttons {
    display: flex;
    justify-content: center;
}
.store-action-buttons .divider-line {
height: 30px;
width: 1px;
background: #616161;
}
.store-action-buttons .store-action-button{
    background: transparent;
    color:#000;
    border:none;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    
    }
    
.change-slick-button{
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: calc(100% - 20px - 30px);
        margin-top: 170px;
        z-index: 100;
        box-shadow: rgb(0 0 0 / 18%) 0px 4px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .change-slick-button.prev{
        left: calc(40px);
    }
    .change-slick-button.prev img{
        transform: rotate(180deg);
        margin-right: 4px;
        height: 14px;
        width: 14px;
    }
    .change-slick-button img{
        margin-left: 2px;
        height: 14px;
        width: 14px;
    }