
* {
    font-family: "Inter", sans-serif;
}
.compare-data {
    margin-top: 40px;

}
.compare-row {
    display:flex;
}
.compare-row .label {
    margin-left: 5%;
    font-size: 18px;
    font-weight: 500;
    color: #616161;
}
.compare-row .data-values{
    width: 100%;
    display:flex;
    justify-content:space-between;
}
.val-title{
    margin-left:10%;
    margin-right:5%;
    font-size: 14px;
    font-weight: 500;
    color: #212121;

    
}
.val-price{
    margin-left:10%;
   
    font-size: 18px;
    font-weight: 500;
    color: #000;
    
}
.val-price:nth-child(2){
    margin-right:20%;
    
}

.compare-products-widget h4 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    color: #212121;
    font-family: "Roboto", sans-serif;
}
.compare-images {

    display:flex;
    justify-content:space-between;
}
.img-plus-title {
    text-align:center;
    margin-left: 8%;
    margin-right: 8%;
}
.img-compare {
    max-width: 250px;
    max-height: 250px;
    object-fit: cover;
}
.compare-products-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.65);
    z-index: 200;
}

.compare-products-widget {
    margin-left: 15%;
    margin-top: 20vh;
    width: 70%;
    height: 64vh;
    background: #fff;

}
.exit-button img{
    height: 15px;
}
.exit-button {
cursor: pointer;
  background:transparent;
  border:none;
  position:absolute;
  float:right;
  right: calc(15% + 20px);  
top: calc(20vh + 20px);
}