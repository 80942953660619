.faq-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}

.faq-questions {
    max-width: 600px;
    width: 80%;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #1A2B6B;;
   
    cursor: pointer;
}
.faq-q {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faq-q-title {
    color: #1A2B6B;
    font-size: 1.8rem;
    line-height: normal;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.faq-answer p {
    padding-top: 1rem;
    line-height: 1.6;
    font-size: 1.2rem;
    color: #6D7380;
}

.faq-questions.active .faq-answer {
    max-height: 300px;
    transition: max-height 1.4s ease;
}


.faq-questions.active svg{
    transform: rotate(180deg);
 
}
svg{
    transition: transform 0.5s ease-in;
}
.cTRFnb{
    max-width: 500px;
    width: 90% !important;
}