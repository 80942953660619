

.BirdLogo {
    
}
 .bird-logo{
	position: relative;
	width: 50px;
	height: 50px;
}

.hair-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

}

.hair-logo{
	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;
	left: 50%;
	background-color: #CC4B06;
	border-radius: 0 125px 125px 0;
}

.face-logo{
	position: absolute;
	width: 70%;
	height: 70%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.white-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;

}

.white-logo{
	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;
	left: 50%;
	border-radius: 0 87.5px 87.5px 0;
	background-color: white;
}

.shadow-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	
}

.shadow-logo{
	position: absolute;
	width: 50%;
	height: 50%;
	top: 50%;
	left: 50%;
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 0 0 87.5px 0;
}

.bigBeak-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 3;

}

.bigBeak-logo{
	position: absolute;
	width: 50%;
	height: 50%;
	top: 0;
	left: 0;
	background-color: #f7ce42;
	border-radius: 87.5px 0 0 0;
}

.smallBeak-container-logo{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;

}

.smallBeak-logo{
	position: absolute;
	width: 25%;
	height: 25%;
	top: 50%;
	left: 25%;
	background-color: #f7a500;
	border-radius: 0 0 0 43.25px;
}

.eye-logo{
	position: absolute;
	width: 25%;
	height: 25%;
	bottom: 51%;
	left: 51%;
	background-color: #18233e;
	border-radius: 50%;
	z-index: 2;

}
