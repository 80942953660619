
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
  
.pc-main-product-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 70px;
    height: calc(100vh - 70px) ;

}
.pc-image-div{
    width: 420px;
   
}
.pc-image {
max-width: 375px;
max-height: 375px;
transition: transform 0.3s ease-in-out;
}


.pc-image:hover {

   

    transform: scale(1.2); /* Enlarge the image on hover */
  
}
    

.pc-product-info{
    max-width: 420px;

}
.pc-main-title{
    font-size: 30px;
   font-family: "Suisse Medium";
   font-weight: 500;
   color:black

}
.best-price-label{
    font-size: 16px;
    font-family: "Suisse Regular";
    color:#757575;
    margin: 0 10px 20px 0px;
}
.pc-main-price{
    display: flex;
    align-items: center;
}
.pc-big-price {
    font-size: 36px;
    font-family: "Suisse Bold";
    color:#212121;
    margin-right: 10px;
    margin-top: 4px;
}
.pc-price-before{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
}
.pc-price-saved {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
}
.pc-main-buy-button{
    margin-top:30px;
    border:none;
    background: #1967D2;
    color: #fff;
    width: 280px;
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
    font-family: "Suisse Medium";
}
.pc-stores-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}
.pc-amountofstores{
    font-size: 24px;
    font-family: "Suisse Medium";
    color:#212121;
    margin-left: 80px;
    width: 100%;

}
.pc-lowest-price{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#212121;
    width: 100%;
    text-align: center;

}

.pc-condition{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#212121;
    width: 100%;
    text-align: center;
}

.stores-paddingdiv{
    min-width: 290px;
}

.pc-stores-listing{
    border-top: 1px solid #e0e0e0;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pc-store-data{
    display: flex;
    align-items: center;
    width: 100%;
    margin-right:80px;

}
.pc-store-circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px 0px 60px;
}
.pc-store-name {
    display: flex;
    align-items: center;
}
.pc-amountOnStore{
    font-size: 11px;
    background: #222;
    color: #fff;
    height: fit-content;
    border-radius: 10px;
    width: fit-content;
    margin-left: 7px;
    margin-top: 4px;
    padding: 0px 7px;
    font-family: "Montserrat";
}
.pc-store-name {
    display: flex;
}
.pc-store-name .p{
    font-family: "Suisse Regular";
    font-size: 20px;
    font-weight: 500;
    color:#212121;
}
.pc-store-trusted{
    font-family: "Suisse Regular";
    font-size: 12px;
    font-weight: 500;
    color:#212121; 
}

.pc-listing-product-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.pc-listing-image{
    max-width: 70px;
    max-height: 70px;
    margin-right: 30px;
}

.pc-listing-price{
    font-family: "Suisse Medium";
    font-size: 20px;
    font-weight: 500;
    color:#212121;
    margin-right: 10px;
}

.pc-listing-save{
    
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    color:#757575;
}

.pc-listing-condition {
    display: flex;
    justify-content: center;
    width: 100%;

}
.pc-listing-condition p{
    font-family: "Suisse Regular";
    font-size: 16px;
    font-weight: 500;
    color:#212121;

}

.pc-visit-site-button{
    border: 1px solid #424242;
    background: transparent;
    max-width: 240px;
    width: 100%;
    height: 50px;
    font-family: "Suisse Regular";
    font-size: 16px;
    color:#424242;
    border-radius: 4px;
    margin-right: 50px;

}

.loading-heart-body{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-heart{
    height: 35px;
    margin-right: 20px;
    margin-top: 4px;
    animation: beat 1.5s infinite ease-in-out;
}
@keyframes beat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
.loading-heart-text{
    font-size: 24px;
    color: #424242;
    font-weight: 400;
    font-family: "Montserrat";
}