
.tutorial-how-to-use{
    max-width: 520px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 40px;
}
 .content-box {
max-width: 600px;
width: 90%;
display: flex;
margin-bottom: 20px;
margin-left: 10px;
margin-right: 10px;
transform: translate(0);
}
.howtouse{
    font-size: 20px;
    margin-bottom: 20px;
}
.how-to-use-content-boxes {
    display: flex;

}

@media (max-width: 1000px) {
    .how-to-use-content-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  }

.howitworks-body{
    flex-direction: column;
 display: flex;
 align-items: center; 
}
  .howitworks-all{
 width: 80%;
 flex-direction: column;
 display: flex;
 align-items: center;
 margin-bottom: 200px;
  }

  .howitworks-title-content{
    width: 100%;
    margin-top: 100px;
    
  }

  .howitworks-title-main{
    font-family: "Pangea Medium";
    font-size: 40px;
    line-height: 80px;
  }

  .howitworks-title-subtitle{
    font-family: "Pangea Medium";
    font-size: 20px;
  }

  .howitworks-content {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .howitworks-instructions{
    display: flex;
  }
 
.howitworks-text-content{
    margin: 3px 0 0 49px;
}

.howitworks-instruction-content{
    width: 400px;
  margin-bottom: 80px;
}

.howitworks-instruction-content:last-child{
    margin-bottom: 0;
} 
 
.howitworks-instruction-title{
    font-size: 20px;
    font-family: "Suisse Regular";
    color: #9e9e9e;
    cursor: default;
}
.howitworks-instruction-title.current{
    color: #212121;
}
.howitworks-instruction-explanation{
    margin-top: 10px;
    font-size: 16px;
    font-family: "Suisse Regular";
    color: #757575;
}
.howitworks-next-button{
    width: 100px;
    height: 40px;
    background: #1967D2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Suisse Regular";
    font-size: 14px;
    border-radius: 4px;
    margin-top: 20px;
    cursor: default;
}

.howitworks-progressbar{
    height: 370px;
    width: 1px;
    background: #bdbdbd;
 
    
  }
.howitworks-progressbar-blue{
    background: #1967D2;
    width: 3px;
    margin-left: -1px;
    height: 50px;
}


.howitworks-progressbar-blue.step2{
    margin-top: 100px;
}
.howitworks-progressbar-blue.step3{
    margin-top: 200px;
}
.howitworks-video{
    max-height: 380px;
}
.tutorial-howtouse{
    box-shadow: 0 5px 5px 0 rgba(154,160,185,.05), 0 5px 30px 0 rgba(166,173,201,.22);
    border-radius: 4px;
    width: 100%;
    max-height: 380px;
}