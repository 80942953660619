
.store-listing {
    text-align: center;
    
}

.title-store-listing {
  font-size: 26px;
  font-family: "Euclid Medium";
  color: #212121;
  margin-left: 150px;
}

.store-listing > .header {
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -30px;
  background: #fff;

}
.sort-after {
    padding-top: 30px;
    padding-right: 12%;
}
.sort-after.mobile {
  padding-top: 0px;
  padding-right: 0;
}
.sort-after-texts {
   cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sort-after-p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #616161;
    font-weight: 500;

}
.sort-after-texts > .sort-after-text-button{
    color: #000;
    font-weight: 500;
    margin-left: 5px;
    margin-right: 2px;
}
.sort-after-container {
    border-radius: 5px;
    width: 150px;
    background: #fafafa;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
    z-index: 10;
   position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sort-after-button {
    padding: none;
    height: 30px;
    width: 150px;
    background: transparent;
    border: none;
}


.items-text{
  font-family: "Suisse Regular";
  font-size: 17px;
  font-weight: 500;
 }

.arrow-down {
    width: 0; 
    height: 0; 

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #616161;
    margin-bottom: 14px;

    
  }

.go-back {
    display: flex;
    flex-direction: row;
}

.back-circle{
    position: fixed;
    margin-top: 10px;
    height: 70px;
    width: 70px;
    margin-left: 40px;
    border-radius: 50%;
    background: white;
    border: 1px SOLID #eeeeee;
    display: flex;
   align-items: center;
   justify-content: center;
  }
  .back-circle img{
    width: 30px;
    max-height: 100%;
    height: auto;
    
  }
  .store-listing p {
    font-size: 12px;
  }
  .store-listing .view-more{
    
    border: 2px solid #F26C25;
    color:#F26C25;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .store-listing .view-more:hover{
    border: 2px solid #F26C25;
    background:#F26C25;
    color:white;
    font-weight: 600;
    
  }