
* {
  font-family: "Suisse Regular", sans-serif;
}
.loading-animation {
  height: 50px;
  width: 50px;
  position: absolute;
  z-index: 9;
}
.line-div-divider{
  width: calc( 100% - 140px);
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 20px;
  margin-left: 70px;
  margin-top: 40px;
}
.sort-after-main .sort-after{
  width: 400px;
  position: absolute;
  margin-left: 80%;
  padding-top: 0px;

}

.store-container{
    display: flex;
  align-items: center;
  justify-content: space-between;
    margin-top: 30px;
    margin-bottom: -30px;
    
  }
  
  .store-container .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .store-container .card-container{
    display: flex;
    margin-right: 10%;
  }
  .store-container .card{
    height: 80px;
    width: 150px;
    background: transparent;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    
    border-radius: 15px;
  
  
  }
  .Loading {
    border: 2px SOLID #eeeeee;
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .search-results{
    margin-left: 70px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .search-results p {
    color: #616161;
    font-size: 14px;
  }
  .search-results h2 {
    font-family: "Suisse Regular";
    color: #212121;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px
  }


  .store-container .container .store-text{
    margin-bottom: 5px;
    font-weight:500;
    font-family: "Suisse Regular", sans-serif;
    font-size: 16px;
    color: #000
  }
  .store-container .container .aliexpress-text{
    margin-top: 4px;
  margin-bottom: 8px;
  }
  
  .store-container .container .other-store-images{
    display: flex;
    flex-direction: row;
    justify-content: center;
   
  }
  
  
  .store-container .container img{
    width: 90px;
    max-height: 100%;
    height: auto;
  }
  
  .store-container .container .other-store-walmart-logo{
    width: 70px;
    max-height: 100%;
    height: auto;
  }
  .store-container .container .other-store-ebay-logo{
    width: 50px;
    max-height: 100%;
    height: auto;
  }

  .store-container-mobile {
    display: flex;
    flex-direction: column;
   
  }

  .search-results-mobile {
    margin-top: 30px;
    margin-left: 20px;
  }


  .search-results-mobile h2 {
    font-family: "Suisse Medium";
    text-align: left;
    color: #212121;
    font-size: 20px;
    font-weight: 400;
    margin-bottom:5px;
  }



  .search-results-mobile p {
    font-family: "Suisse Regular";
    text-align: left;
    color: #616161;
    font-size: 14px;
    font-weight: 400;
  }

  .store-container-mobile .line-div-divider {
margin-top: 20px;
  }


  .mobile-card-container{
    display: flex;
    justify-content: space-between;
   
  }

  .mobile-loading-card{
    width: 160px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }

  .mobile-loading-card img{
   width: 30px;
   height: 30px; 
   margin-right: 20px;
  }

  .mobile-store-text{
    font-family: "Suisse Regular";
    color: #333;
    font-size: 16px;
    margin-right: 20px;
  }